import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import Price from '../Common/Price';
import moment from 'moment-jalaali';
import { Link } from 'react-router-dom';
import Form from '../Common/Form';
import { editMessage, SwitchAccountWindow, toggleAccountWindow, changeSafarmarketLayout } from '../../Actions/VisualDataAction';
import { getPreReserveID, postReserve } from '../../Actions/HotelAction';
import Validation from '../Common/Validation';
import Loading from '../Common/Loading';
import { Currency } from '../../Enum/Models';
import { Tooltip,Collapse } from 'antd';
import 'antd/dist/antd.css';
import { CountryCode, CountryPostalCode } from '../../Enum/Models';
import LayoutConfig from '../../Config/layoutConfig';
import 'react-phone-number-input/style.css';
import Rating from '../Common/Rating';
import StickySides from '../Common/StickySides';
import AcceptRemarksModal from "./AcceptRemarksModal";

class GetPassengerSafarmarket extends Form {
  //init
  state = {
    ...this.state,
    username: this.props.userData?.emailAddress || undefined,
    isSendRequest: false,
    phoneNumber: null,
    showSelectedRoomDetails: false,
    showAcceptRemarksModal: false,
    submitLoading: false
  };
  FormDOM = [];
  preReserveKey;
  init = () => {
    this.props.editMessage(false);
    let parameters = queryString.parse(this.props.location.search);
    let params = new URLSearchParams();
    this.preReserveKey = parameters.preReserveKey;
    params.append('preReserveKey', parameters.preReserveKey);
    this.props.getPreReserveID(params);

    this.props.setSafarmarketLayout(true);
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.editMessage(false);
    this.init();
    //super.componentDidMount(this.FormDOM);
  }

  componentWillUnmount() {
    this.props.setSafarmarketLayout(false);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.userData !== this.props.userData) {
      if (this.props.userData !== null) {
        this.setState({
          username: this.props.userName,
        });
      }
      this.render();
    }
    if (this.props.respond === true && this.state.isSendRequest === true) {
      let url = `/payment/reserve?reserveId=${this.props.reserveId}`;
      const parameters = queryString.parse(this.props.location.search);
      if (parameters.smid) {
        url = `/payment/reserve?reserveId=${this.props.reserveId}&smid=${parameters.smid}`;
      }
      this.props.history.push(url);
    }
  }

  callPrereserve(){
    this.setState(
      {
        isSendRequest: true,
        submitLoading: true
      },
      () => {
        this.props.postReserve(this.state.DataInput, this.props.LAN === "IR" ? "fa-IR" : "en-US");
      }
    );
  }

  handleSubmit(event) {
    event.preventDefault();
    super.validateForm(event, () => {
      this.props.editMessage(false);
      //exception code for adding phone number
      let inputData = JSON.stringify(this.state.DataInput);
      inputData = JSON.parse(inputData);
      let code = inputData.reserver.postalcode;
      if (code.substring(0, 2) === '00') {
        code = '+' + code.substring(2);
      }
      inputData.reserver.email = inputData.reserver.email || null; 
      inputData.reserver.phoneNumber = code + '' + inputData.reserver.phoneNumber;
      inputData.reserver.username = inputData.reserver.username ? inputData.reserver.username : this.state.DataInput.reserver.email?this.state.DataInput.reserver.email: inputData.reserver.phoneNumber;


      const remarks = this.props.hotelInfo?.accommodation?.rooms?.[0]?.remarks;
      
      if(remarks){
        this.setState({
          DataInput: inputData,
          showAcceptRemarksModal: true
        });
      }else{       
        this.setState(
          {
            DataInput: inputData,
          },
          this.callPrereserve
        );
      }      
    });
  }
  onChangeValidation(event, type, maxLength) {
    if (maxLength) {
      if (event.target.value.length > maxLength) {
        const prevVal = event.target.value.substring(0, maxLength);
        event.target.value = prevVal;
      }
    }
    let regex;
    switch (type) {
      case 'number':
        regex = /^[0-9۰-۹٠-٩]*$/;
        break;
      case 'enAlphabet':
        regex = /^[a-zA-Z ]*$/;
        break;
      case 'faAlphabet':
        regex = /^[\u0600-\u06FF ]*$/;
        break;
      case 'email':
        regex = /[^\u0600-\u06FF\s]/;
        break;
      default:
        regex = /^[a-zA-Z ]*$/;
    }

    if (!regex.test(event.target.value)) {
      const prevVal = event.target.value.substring(0, event.target.value.length - 1);
      if (regex.test(prevVal)) {
        event.target.value = prevVal;
      } else {
        event.target.value = '';
      }
    }
  }

  ifEnglish = (string) => {
    const regex = /^[a-zA-Z ]*$/;
    if (!regex.test(string)) {
      return '';
    }
    return string;
  };

  passengers = (adults, children) => (
    <div>
      <span className="inline-block margin-start-light">{adults}</span>
      <span className="inline-block margin-start-light">{adults > 1 ? this.props.Dictionary.adults : this.props.Dictionary.adult}</span>
      {children > 0 && (
        <>
          <span className="inline-block margin-start-light">{this.props.Dictionary.and}</span>
          <span className="inline-block margin-start-light">{children}</span>
          <span className="inline-block">{children > 1 ? this.props.Dictionary.children : this.props.Dictionary.child}</span>
        </>
      )}
    </div>
  );

  getBacktoHotelDetails = () => {
    const {hotelInfo} = this.props;
    if (hotelInfo) {
      const adultsValue = hotelInfo.accommodation.rooms.reduce((adults,roomItem)=>[...adults,roomItem.adults] , []).join(",");
      const childrenValue = hotelInfo.accommodation.rooms.reduce((children,roomItem)=>[...children,roomItem.children] , []).join(",");
      const agesValue = hotelInfo.accommodation.rooms.reduce((ages,roomItem)=>[...ages,roomItem.ages.join(",")] , []).join(",");
      const {checkIn,checkOut} = hotelInfo.date;
      const hotelId =hotelInfo.accommodation.id;
      const safarmarketId = queryString.parse(this.props.location.search).smid;
      let url = `/hotel/details?Id=${hotelId}&Adults=${adultsValue}&Children=${childrenValue}&Ages=${agesValue}&Checkin=${checkIn}&Checkout=${checkOut}`;
      if (safarmarketId){
        url += `&safarmarketId=${safarmarketId}&utm_source=safarmarket&utm_medium=redirection&utm_medium=hotel`
      }
      this.props.history.push(url);
    }
  };
  render() {
    const { Dictionary, LAN } = this.props;
    const {Panel} = Collapse;
    let night = 0;
    if (this.props.currencyCode !== null) {
      let currency = this.props.currencyCode.toString();
      this.currecySign = Currency[currency];
    } else {
      this.currecySign = '???';
    }
    let RoomRender = [];
    const { respond, hotelInfo, userData } = this.props;

    const hasRemark = hotelInfo?.accommodation?.rooms?.find(r => r.remarks);
    
    let userInfoStatus = false;
    if (userData && userData.id){
       userInfoStatus = true;
    }

    const updatedUserData = userInfoStatus? {
      ...userData,
      firstName: this.ifEnglish(userData.firstName),
      lastName: this.ifEnglish(userData.lastName),
    }:undefined;

    if (respond) {
      if (hotelInfo != null) {
        night = hotelInfo.date.duration;
        if (hotelInfo.accommodation) {
          hotelInfo.accommodation.rooms.forEach((item) => {
            let passenger = {
              adults: [],
              children: [],
              name: item.name,
              board: item.board,
            };
            for (let itr = 1; itr <= item.adults; itr++) {
              passenger.adults.push(itr);
            }
            for (let itr = 1; itr <= item.children; itr++) {
              passenger.children.push(itr);
            }
            RoomRender.push(passenger);
          });
        }
      }
    }
    let passengerCounter = -1;
    let userPhoneNumber = undefined;

    let defaultNationality = this.props.IPLocation || LAN === 'IR' ? 'IR' : LAN === "TR" ? "TR" : 'NO';
    if (process.env.REACT_APP_SERVER_LOCALSERVER === "itours.ir"){
      defaultNationality = "IR";
    }
    if(hotelInfo?.nationalityCode){
      defaultNationality= hotelInfo.nationalityCode;
    }

    
    return !hotelInfo?.accommodation ? (
      <Loading fullPage description={Dictionary.GettingPassengerInformation + ' ...'} />
    ) : (
      <div className="get-passenger-form-holder getpassenger-page">

        {this.state.showAcceptRemarksModal && (
          <AcceptRemarksModal 
            onAccept={ () => {this.callPrereserve() }}
            remarks={hotelInfo?.accommodation?.rooms?.[0]?.remarks}
            submitLoading={this.state.submitLoading}
          />
        )}

        <form id={'reserveForm'} onSubmit={this.handleSubmit.bind(this)} ref={(node) => (this.FormDOM = node)} noValidate>
          <div className="page-container no-padding-mobile-tablet">
            <StickySides
              main={<>
              <div className='bg-white card-border card-padding rounded-medium margin-bottom hidden-large'>
                <div className="sm-check-in-out-wrapper">
                  <strong className="margin-end-light no-wrap">
                    <span className="itours-icon calendar-dark icon-small margin-end-light font-13" />
                    تاریخ ورود و خروج:
                  </strong>
                  <span className="no-wrap">
                    {moment(hotelInfo?.date?.checkIn).format('jYYYY/jMM/jDD')} - {moment(hotelInfo?.date?.checkOut).format('jYYYY/jMM/jDD')}
                  </span>
                </div>
              </div>
              <div className="padding-h-mobile-tablet">
                <div className="bg-white rounded padding-v-15 relative margin-bottom">
                  <div className="padding-h-15 flex-ends-medium middle">
                    <div className="flex-ends middle">
                      <h5 className="no-margin-bottom font-16 md-font-18 margin-end-half semi-bold en-font">{hotelInfo.accommodation.name}</h5>
                      <Rating number={hotelInfo.accommodation.rating} />
                    </div>
                    {/* <button type="button" className="link-style-btn button sm-change-room-btn" onClick={this.getBacktoHotelDetails}>
                      تغییر اتاق ها
                    </button> */}
                  </div>
                  <hr className="margin-top-half margin-bottom-half" />
                  <div className="padding-h-15 flex-ends-medium middle">
                    <div className="en-font">
                      <span className="itours-icon location-dark icon-small margin-end-light" />
                      {hotelInfo.accommodation.address}
                    </div>
                    <div>
                      <span className="itours-icon moon-dark icon-small margin-end-light" />
                      {night} شب
                    </div>
                  </div>
                </div>

                <div className="bg-white rounded padding-v-15 relative margin-bottom">
                  <div className="padding-h-15">
                    <h5 className="no-margin-bottom font-16 md-font-18 semi-bold">مشخصات خریدار</h5>
                  </div>
                  <hr className="margin-top-half margin-bottom-half" />
                  <div className="padding-h-15">
                    <div className="inline-radio">
                      <div className="radio-item">
                        <input className="custom-radio" type="radio" name={'reserver.gender'} value={true} defaultChecked={true} />
                        <label className="radio-label">
                          <span className="radio-mark"></span>
                          {Dictionary.male}
                        </label>
                      </div>
                      <div className="radio-item">
                        <input className="custom-radio" type="radio" name={'reserver.gender'} value={false} />
                        <label className="radio-label">
                          <span className="radio-mark"></span>
                          {Dictionary.female}
                        </label>
                      </div>
                    </div>
                    <div className="float-row margin-bottom">
                      <div className="col-small-12 col-medium-6">
                        <div className="form-group relative required">
                          <label className="form-label">{Dictionary.firstNameEn}</label>
                          <input
                            onChange={(e) => {
                              this.onChangeValidation(e, 'enAlphabet');
                            }}
                            data-validator-type="alphabet"
                            className="form-input placeholder-right en-input-text"
                            data-validator={true}
                            placeholder={Dictionary.firstName}
                            name="reserver.firstName"
                            type="text"
                            defaultValue={updatedUserData?.firstName || ''}
                          />
                          <Validation model={this.state} feild="ValidatorList" name="reserver.firstName" />
                        </div>
                      </div>
                      <div className="col-small-12 col-medium-6">
                        <div className="form-group relative required">
                          <label className="form-label">{Dictionary.lastNameEn}</label>
                          <input
                            onChange={(e) => {
                              this.onChangeValidation(e, 'enAlphabet');
                            }}
                            data-validator-type="alphabet"
                            className="form-input placeholder-right en-input-text"
                            data-validator={true}
                            placeholder={Dictionary.lastName}
                            name="reserver.lastName"
                            type="text"
                            defaultValue={updatedUserData?.lastName || ''}
                          />
                          <Validation model={this.state} feild="ValidatorList" name="reserver.lastName" />
                        </div>
                      </div>
                      <div className="col-small-12 col-medium-6">
                        <div className="form-group relative required">
                          <label className="form-label">شماره تماس</label>
                          <div className="select-input-pair-holder" dir="ltr">
                            <select className="form-select" name={'reserver.postalcode'} dir="ltr">
                              {CountryPostalCode.sort((a, b) => {
                                let x = a.code.toLowerCase();
                                let y = b.code.toLowerCase();
                                if (x < y) {
                                  return -1;
                                }
                                if (x > y) {
                                  return 1;
                                }
                                return 0;
                              }).map((item) => {
                                let seleceted = false;
                                if (userData && userData.phoneNumber) {
                                  let code = item.value;
                                  if (code.substring(0, 2) === '00') {
                                    code = '+' + code.substring(2);
                                  }
                                  if (userData.phoneNumber.includes(code)) {
                                    seleceted = true;
                                    let phone_Number = userData.phoneNumber.replace(code, '');
                                    userPhoneNumber = phone_Number;
                                  }
                                } else {
                                  if (
                                    (item.value === "0098" && LAN === "IR") 
                                    || (item.value === "0090" && LAN === "TR") 
                                    || (item.value === "00971" && LAN === "AR" && (process.env.REACT_APP_THEME !=='AYANAIRCOM')) 
                                    || (item.code === "Norway (+47)" && LAN !== "AR" && LAN !== "IR" && process.env.REACT_APP_THEME !=='AYANAIRCOM')
                                    || (item.value === '00964' && process.env.REACT_APP_THEME ==='AYANAIRCOM')
                                  ) {
                                    seleceted = true;
                                  }
                                }
                                return (
                                  <option key={item.code} value={item.value} selected={seleceted}>
                                    {item.code}
                                  </option>
                                );
                              })}
                            </select>
                            <input
                              onChange={(e) => {
                                this.onChangeValidation(e, 'number');
                              }}
                              className="form-input placeholder-right en-input-text"
                              type={'tel'}
                              data-validator={LAN === 'IR'}
                              defaultValue={userPhoneNumber ? userPhoneNumber : undefined}
                              placeholder={'9XXXXXXXXX'}
                              name={'reserver.phoneNumber'}
                            />
                          </div>
                          <Validation model={this.state} feild="ValidatorList" name="reserver.phoneNumber" />
                        </div>
                      </div>
                      <div className="col-small-12 col-medium-6">
                        <div className="form-group relative required">
                          <label className="form-label">{Dictionary.email}</label>
                          <input
                            onChange={(e) => {
                              this.onChangeValidation(e, 'email');
                            }}
                            className="form-input placeholder-right en-input-text"
                            data-validator={true}
                            placeholder={Dictionary.enterEmail}
                            defaultValue={userInfoStatus ? userData.emailAddress:""}
                            name="reserver.email"
                            type="email"
                          />
                          <Validation model={this.state} feild="ValidatorList" name="reserver.email" />
                        </div>
                      </div>
                    </div>
                  </div>
                  {userInfoStatus ? <input type="hidden" name="reserver.userID" value={userData.id} />:null}
                  <input type="hidden" name="reserver.username" defaultValue={this.state.username} />
                </div>

                {RoomRender.map((item, index) => {
                  return (
                    <div className="bg-white rounded padding-v-15 relative margin-bottom" key={index}>
                      <div className="padding-h-15">
                        <h5 className="no-margin-bottom font-16 md-font-18 semi-bold">
                          {Dictionary.room} {index + 1}
                        </h5>
                      </div>
                      <hr className="margin-top-half margin-bottom-half" />
                      <div className="padding-h-15">
                        <div className="card-padding box-border border-2 border-dashed margin-top margin-bottom">
                          <div className="flex-ends middle margin-bottom-light">
                            <span className="inline-block en-font font-16 semi-bold" dir="ltr">
                              ({item.name})
                            </span>
                            <span className="gray-tag small-tag font-12 no-wrap">
                              {item.board?.name}
                              <span className="pipe margin-start-light margin-end-light" />
                              {item.board?.code}
                            </span>
                          </div>
                          {hotelInfo.cancellationPolicy?.status === "Refundable"?(
                            <Collapse
                              expandIconPosition={"right"}
                              bordered={false}
                              defaultActiveKey={null}
                              className="header-with-extra reverse"
                            >
                              <Panel
                                extra={<div onClick={e => {e.stopPropagation();}}>
                                  {this.passengers(item.adults?.length, item.children?.length)}
                                </div>}
                                header={<button type="button" className="button link-style-btn">
                                  قوانین استرداد
                                  <span className="css-icon down-caret-css-icon margin-start-light" />
                                </button>}
                              >
                                <div className='card-padding'>
                                  <div className="margin-bottom">
                                    امکان کنسل کردن فقط تا تاریخ های زیر میسر است و در
                                    صورت درخواست کنسلی، 
                                    <b> ده درصد مبلغ (حداقل  2,000,000 ریال) </b>
                                    بابت کارمزد خدمات از مبلغ کل رزرو کسر میگردد.
                                  </div>
                                  <ul className="bullet-list ">
                                    {hotelInfo.cancellationPolicy?.fees?.map((rule, index) => {
                                      if (rule.amount > 0) {
                                        return (
                                          <li key={index}>
                                            <span className="inline-block margin-end-light">
                                              <Price currency={this.props.currency}>
                                                {rule.amount}
                                              </Price>
                                            </span>
                                            <span className="inline-block margin-end-light">
                                              {Dictionary.afterDate}
                                            </span>
                                            <span className="inline-block">
                                              <Tooltip
                                                title={
                                                  <span className="en-font">
                                                    {moment(
                                                      rule.fromDate.split("T")[0]
                                                    ).format("D/M/YYYY")}
                                                  </span>
                                                }
                                              >
                                                {moment(
                                                  rule.fromDate.split("T")[0]
                                                ).format("D MMM YYYY")}
                                              </Tooltip>
                                              {LAN === "IR" && (
                                                <Tooltip
                                                  title={moment(
                                                    rule.fromDate.split("T")[0]
                                                  ).format("jYYYY/jM/jD")}
                                                >
                                                  (
                                                  {moment(
                                                    rule.fromDate.split("T")[0]
                                                  ).format("jD jMMMM jYYYY")}{" "}
                                                  )
                                                </Tooltip>
                                              )}
                                            </span>
                                          </li>
                                        );
                                      } else {
                                        return null;
                                      }
                                    })}
                                  </ul>
                                </div>
                              </Panel>
                          </Collapse>
                          ):
                          <div className="flex-ends middle">
                            {this.passengers(item.adults?.length, item.children?.length)}
                            <div className="gray-tag font-12">غیر قابل استرداد</div>
                          </div>                                
                          }
                        </div>

                        <div className="float-row">
                          <div>
                            <div className="col-small-12">{Dictionary.informationOfAdultTravelers}</div>
                            {item.adults.map((item, indexIN) => {
                              passengerCounter++;
                              return (
                                <div key={indexIN}>
                                  <div className="col-small-12">
                                    <div className="inline-radio">
                                      <div className="radio-item">
                                        <input
                                          className="custom-radio"
                                          type="radio"
                                          name={`passengers[${passengerCounter}].gender`}
                                          value={true}
                                          defaultChecked={true}
                                        />
                                        <label className="radio-label">
                                          <span className="radio-mark"></span>
                                          {Dictionary.male}
                                        </label>
                                      </div>
                                      <div className="radio-item">
                                        <input className="custom-radio" type="radio" name={`passengers[${passengerCounter}].gender`} value={false} />
                                        <label className="radio-label">
                                          <span className="radio-mark"></span>
                                          {Dictionary.female}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-small-12 col-medium-4">
                                    <div className="form-group relative required">
                                      <label className="form-label">{Dictionary.firstNameEn}</label>
                                      <input
                                        onChange={(e) => {
                                          this.onChangeValidation(e, 'enAlphabet');
                                        }}
                                        data-validator-type="alphabet"
                                        className="form-input"
                                        data-validator={true}
                                        placeholder={Dictionary.firstName}
                                        name={`passengers[${passengerCounter}].firstName`}
                                        type="text"
                                      />
                                      <Validation model={this.state} feild="ValidatorList" name={`passengers[${passengerCounter}].firstName`} />
                                    </div>
                                  </div>
                                  <div className="col-small-12 col-medium-4">
                                    <div className="form-group relative required">
                                      <label className="form-label">{Dictionary.lastNameEn}</label>
                                      <input
                                        onChange={(e) => {
                                          this.onChangeValidation(e, 'enAlphabet');
                                        }}
                                        data-validator-type="alphabet"
                                        className="form-input"
                                        data-validator={true}
                                        placeholder={Dictionary.lastName}
                                        name={`passengers[${passengerCounter}].lastName`}
                                        type="text"
                                      />
                                      <Validation model={this.state} feild="ValidatorList" name={`passengers[${passengerCounter}].lastName`} />
                                    </div>
                                  </div>
                                  <div className="col-small-12 col-medium-4">
                                    <div className="form-group relative required">
                                      <label className="form-label">{Dictionary.nationality}</label>
                                      <select
                                        className="form-select"
                                        name={`passengers[${passengerCounter}].nationalityCode`}
                                        defaultValue={defaultNationality}
                                        data-validator={true}>
                                        {' '}
                                        <option disabled selected value="false">
                                          {' '}
                                          --{' '}
                                        </option>
                                        {CountryCode.map((item) => (
                                          <option value={item.value} key={item.value}>
                                            {item.code}
                                          </option>
                                        ))}
                                      </select>
                                      <Validation model={this.state} feild="ValidatorList" name={`passengers[${passengerCounter}].nationalityCode`} />
                                    </div>
                                  </div>
                                  <input data-validator={false} value={index + 1} name={`passengers[${passengerCounter}].roomNumber`} type="hidden" />
                                  <input data-validator={false} value={'Adult'} name={`passengers[${passengerCounter}].ageCategory`} type="hidden" />
                                </div>
                              );
                            })}
                          </div>
                          <div>
                            <div className="col-small-12">{item.children.length > 0 && <span>{Dictionary.informationOfChildrenTravelers}</span>}</div>
                            {item.children.map((item, indexIN) => {
                              passengerCounter++;
                              return (
                                <>
                                  <div className="col-small-12">
                                    <div className="inline-radio">
                                      <div className="radio-item">
                                        <input
                                          className="custom-radio"
                                          type="radio"
                                          name={`passengers[${passengerCounter}].gender`}
                                          value={true}
                                          defaultChecked={true}
                                        />
                                        <label className="radio-label">
                                          <span className="radio-mark"></span>
                                          {Dictionary.male}
                                        </label>
                                      </div>
                                      <div className="radio-item">
                                        <input className="custom-radio" type="radio" name={`passengers[${passengerCounter}].gender`} value={false} />
                                        <label className="radio-label">
                                          <span className="radio-mark"></span>
                                          {Dictionary.female}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-small-12 col-medium-4">
                                    <div className="form-group relative required">
                                      <label className="form-label">{Dictionary.firstNameEn}</label>
                                      <input
                                        data-validator-type="alphabet"
                                        className="form-input"
                                        data-validator={true}
                                        placeholder={Dictionary.firstName}
                                        name={`passengers[${passengerCounter}].firstName`}
                                        type="text"
                                      />
                                      <Validation model={this.state} feild="ValidatorList" name={`passengers[${passengerCounter}].firstName`} />
                                    </div>
                                  </div>
                                  <div className="col-small-12 col-medium-4">
                                    <div className="form-group relative required">
                                      <label className="form-label">{Dictionary.lastNameEn}</label>
                                      <input
                                        data-validator-type="alphabet"
                                        className="form-input"
                                        data-validator={true}
                                        placeholder={Dictionary.lastName}
                                        name={`passengers[${passengerCounter}].lastName`}
                                        type="text"
                                      />
                                      <Validation model={this.state} feild="ValidatorList" name={`passengers[${passengerCounter}].lastName`} />
                                    </div>
                                  </div>
                                  <div className="col-small-12 col-medium-4">
                                    <div className="form-group relative required">
                                      <label className="form-label">{Dictionary.nationality}</label>
                                      <select
                                        className="form-select"
                                        name={`passengers[${passengerCounter}].nationalityCode`}
                                        defaultValue={LAN === 'IR' ? 'IR' : 'NO'}
                                        data-validator={true}>
                                        {' '}
                                        <option disabled selected value="false">
                                          {' '}
                                          --{' '}
                                        </option>
                                        {CountryCode.map((item) => (
                                          <option value={item.value} key={item.value}>
                                            {item.code}
                                          </option>
                                        ))}
                                      </select>
                                      <Validation model={this.state} feild="ValidatorList" name={`passengers[${passengerCounter}].nationalityCode`} />
                                    </div>
                                  </div>
                                  <input data-validator={false} value={index + 1} name={`passengers[${passengerCounter}].roomNumber`} type="hidden" />
                                  <input data-validator={false} value={'Child '} name={`passengers[${passengerCounter}].ageCategory`} type="hidden" />
                                </>
                              );
                            })}
                          </div>
                          <input type="hidden" name="preReserveKey" value={this.preReserveKey} />
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className="bg-white rounded padding-v-15 relative margin-bottom">
                  <div className="padding-h-15">
                    <h5 className="no-margin-bottom font-16 md-font-18 semi-bold">{Dictionary.description}</h5>
                  </div>
                  <hr className="margin-top-half margin-bottom-half" />
                  <div className="padding-h-15">
                    <div className="form-group">
                      <p>
                        {Dictionary.EnterYourRequestsHereAndWeWillShareThemWithTheHotel}.
                        {Dictionary.SpecialRequestsAreNotGuaranteedButTheHotelWillDoEverythingToMeetYourNeeds}
                      </p>
                      <textarea className="form-textarea" name="specialRequest"></textarea>
                    </div>
                  </div>
                </div>
                {/* {hotelInfo.accommodation.rooms[0].remarks && (
                      <div className="card box-border bg-white card-padding margin-top">
                        <div className=" margin-bottom">
                          <h5 className="normal-title">{Dictionary.remarks}:</h5>
                          <div className="justified en-font" dir="ltr" dangerouslySetInnerHTML={{ __html: hotelInfo.accommodation.rooms[0].remarks }} />
                        </div>
                      </div>
                    )} */}

                {hasRemark ? (
                  // <div className="card rounded bg-white margin-top">
                  //   <div className=" margin-bottom">
                  //     <div className="hotel-has-remarks rounded">
                  //         <span className="itours-icon yellow-warning margin-end-light inline-block-middle" />
                  //         {Dictionary.pleaseReadTheBookingRemarksOfThisHotelCarefully}.
                  //     </div>

                  //     <div className="justified card-padding en-font" dir="ltr" dangerouslySetInnerHTML={{ __html: hotelInfo.accommodation.rooms[0].remarks }} />

                  //     <div className="border-top card-padding">
                  //       <div className="checkbox-holder has-link">
                  //         <input className="custom-checkbox" type="checkbox" data-validator={true} name={`acceptRuls`} />
                  //         <label className="checkbox-label font-14">
                  //           {LAN === 'IR' ? (
                  //             <>
                  //               <Link to={'/terms'} className="content-link">
                  //                 قوانین و مقررات آیتورز {' '}
                  //               </Link>
                  //               و ملاحظات رزرو این هتل را خوانده ام و با آن موافق هستم.
                  //             </>
                  //           ) : (
                  //             <>
                  //               {Dictionary.iHaveReadAndAgreeToThe}
                  //               <Link to={'/terms'} className="content-link" target="_blank">
                  //                 {Dictionary.termsAndConditions}
                  //               </Link>
                  //             </>
                  //           )}
                  //         </label>
                  //         <Validation model={this.state} feild="ValidatorList" name={`acceptRuls`} />
                  //       </div>
                  //     </div>

                  //   </div>
                  // </div>
                  null
                ):(
                  <div className="checkbox-holder has-link">
                    <input className="custom-checkbox" type="checkbox" data-validator={true} name={`acceptRuls`} />
                    <label className="checkbox-label font-14">
                      {LAN === 'IR' ? (
                        <>
                          <Link to={'/terms'} className="content-link">
                            قوانین و مقررات آیتورز{' '}
                          </Link>
                          را خوانده ام و با آن موافق هستم.
                        </>
                      ) : (
                        <>
                          {Dictionary.iHaveReadAndAgreeToThe}
                          <Link to={'/terms'} className="content-link" target="_blank">
                            {Dictionary.termsAndConditions}
                          </Link>
                        </>
                      )}
                    </label>
                    <Validation model={this.state} feild="ValidatorList" name={`acceptRuls`} />
                  </div>
                )}

              </div>
              </>
              }
              aside={<div className="bg-white card-border card-padding rounded-medium no-padding-tablet no-padding-mobile">
              <div className="sm-check-in-out-wrapper hidden-xsmall hidden-small hidden-medium">
                <strong className="margin-end-light no-wrap">
                  <span className="itours-icon calendar-dark icon-small margin-end-light font-13" />
                  تاریخ ورود و خروج:
                </strong>
                <span className="no-wrap">
                  {moment(hotelInfo?.date?.checkIn).format('jYYYY/jMM/jDD')} - {moment(hotelInfo?.date?.checkOut).format('jYYYY/jMM/jDD')}
                </span>
              </div>

              <div className="sm-fix-bottom-mobile">
                <hr className='sm-hr hidden-xsmall hidden-small hidden-medium' />
                <p className='font-14 text-center margin-bottom-half  hidden-medium hidden-large hidden-xlarge'>
                  <span className='itours-icon icon-small info-icon margin-end-light' />
                  با کلیک روی تایید و ادامه <Link to={'/terms'}> قوانین و مقررات </Link> را پذیرفته اید.
                </p>
                <div className="selected-room-footer">
                  <div>
                    <div className="inline-medium margin-end-half font-12 sm-font-14 xs-margin-bottom-half">مبلغ قابل پرداخت:</div>
                    <b className="new-price inline-medium font-16 sm-font-20">
                        <Price currency={this.props.currencyCode}>
                          {hotelInfo.salePrice}
                        </Price>
                    </b>
                  </div>
                  <p className='font-14 text-center no-margin-bottom hidden-small hidden-xsmall'>
                    <span className='itours-icon icon-small info-icon margin-end-light' />
                    با کلیک روی تایید و ادامه <Link to={'/terms'}> قوانین و مقررات </Link> را پذیرفته اید.
                  </p>
                  <button className="button red-btn" type="submit" >
                    تایید و ادامه
                    {this.state.submitLoading && <span className="loading-inline-circle margin-start-light" />}
                  </button>
                </div>
              </div>
            </div>}
            />

          </div>
        </form>
      </div>
    );
  }
}
const dispatchMapProp = (dispatch) => ({
  editMessage: (param) => {
    dispatch(editMessage(param));
  },
  getPreReserveID: (param) => {
    dispatch(getPreReserveID(param));
  },
  switchLogin: (mode) => {
    dispatch(SwitchAccountWindow(mode));
  },
  check: () => dispatch(toggleAccountWindow()),
  postReserve: (params, acceptLanguage) => dispatch(postReserve(params, acceptLanguage)),
  setSafarmarketLayout: (data) => dispatch(changeSafarmarketLayout(data)),
});
const stateMapProp = (state) => ({
  respond: state.VisualData.Responded,
  hotelInfo: state.HotelSearch.selectedHotel,
  userData: state.UserData.userData,
  reserveId: state.HotelSearch.reserveId,
  currencyCode: state.UserData.Currency,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
export default withRouter(connect(stateMapProp, dispatchMapProp)(GetPassengerSafarmarket));
