import React from "react";
import PropTypes from "prop-types";
import moment from "moment-jalaali";
import airplane from "../../image/airplane.svg";
import Price from "../Common/Price";
import { Tag, Button,Tooltip } from "antd";
import Rate from "../Common/Rate";
import { connect } from "react-redux";
import FlightItinerary from "../Flight/FlightItinery";
import HotelNoImage from "../../image/default-hotel-image.svg";
import LayoutConfig from "../../Config/layoutConfig";
import { showDuration } from "../../helpers/helpers";

class PackageSummary extends React.PureComponent {
  state = {
    showItinerariModal: false,
  };
  componentDidUpdate(prevProps) {
    if(this.props.detailsIsOpen && prevProps.detailsIsOpen!== this.props.detailsIsOpen){
        this.openItinerariModal();
    }
  }
  openItinerariModal = () => {
    this.setState({
      showItinerariModal: true,
    });
  };
  closeItinerariModal = () => {
    this.setState({
      showItinerariModal: false,
    });
    if(this.props.closeDetailsModal){
      this.props.closeDetailsModal();
    }
  };
  static propTypes = {
    flightModel: PropTypes.any.isRequired,
    hotelModel: PropTypes.any.isRequired,
    currencyCode: PropTypes.any.isRequired
  };

  showPassenger(cnt, type, price) {
    const { Dictionary } = this.props;
    return (
      <div className={"pair-row"}>
        <div className="passenger-type-title">
          {cnt} <span> x </span>
          {type === "ADT" && Dictionary.adult}
          {type === "CHD" && Dictionary.child}
          {type === "INF" && Dictionary.infant}
        </div>
        <div dir="ltr">
          <Price currency={this.props.currencyCode}>{price}</Price>
        </div>
      </div>
    );
  }
  render() {
    const { Dictionary, LAN } = this.props;

    const isStyle3 = LayoutConfig.themeClassName === 'style-3';

    return (
      <div className="package-summary-card bg-white box-border card-padding">
          <div className="clearfix">
            <button
              className="button blue-btn pull-end"
              type="button"
              onClick={this.openItinerariModal}
            >
              {Dictionary.flightDetails}
            </button>
            <h5 className="normal-title">{Dictionary.reservationDetails}</h5>
              {/* {
              (this.props.supplierKey && (this.props.supplierKey.slice(0, 3) === "009") )
              ||
              (this.props.providerName && (this.props.providerName ==="TravelFusion") )
              ?
                <>
                <Tag color="red" className="itours-tag font-16">{Dictionary.nonChangeable}</Tag>
                  <Tag color="red" className="itours-tag font-16">{Dictionary.nonRefundable}</Tag>
                </>
              :
                null
              } */}
            <div className="selected-flights-info">
              {this.props.flightModel.map((item) => {
                return (
                  <div
                    className="margin-bottom-light"
                    key={item.flightSegments[0].flightNumber}
                  >
                    <div>
                      <strong className="font-14 margin-end-light">
                        {moment(
                          item.flightSegments[0].departureDateTime
                        ).format("YYYY-MM-DD")}
                      </strong>
                      {LAN === "IR" ? (
                        <span className="font-12">
                          (
                          {moment(
                            item.flightSegments[0].departureDateTime
                          ).format("jYYYY-jMM-jDD")}
                          )
                        </span>
                      ) : null}
                    </div>
                    <div className="route-item">
                      <div className="route-item-flex">
                        <div className="airline-logo-holder summery-airline-logo">
                          <img
                            src={
                              item.flightSegments[0].marketingAirline.photoUrl
                            }
                            alt={item.flightSegments[0].marketingAirline.name}
                            className="airline-logo"
                          />
                        </div>
                        <div>
                          <div className="router">
                            <div className="originInfo">
                              <div className="time">
                                <strong>
                                  {moment(
                                    item.flightSegments[0].departureDateTime
                                  ).format("HH:mm")}
                                </strong>
                              </div>
                              <div className="codeCity">
                                {
                                  item.flightSegments[0].departureAirport
                                    .locationCode
                                }
                              </div>
                            </div>
                            <div className="stop-info text-center">
                              <div className="stop">
                                {item.flightSegments.length - 1 === 0 ? (
                                  <span className={isStyle3?"golden":"green"}>
                                    {Dictionary.direct}
                                  </span>
                                ) : (
                                  <span>
                                    {item.flightSegments.length - 1}{" "}
                                    {Dictionary.stops}
                                  </span>
                                )}
                              </div>
                              <div className="dashed-line">
                                {" "}
                                <img
                                  src={airplane}
                                  alt="airplane"
                                  title={"airplane"}
                                />
                              </div>
                              <div className="duration">
                                {item.journeyDuration === "00:00:00" ? "":
                                <>
                                  {Dictionary.journeyDuration}
                                  {showDuration(item.journeyDuration)}
                                </>
                                }
                              </div>
                            </div>
                            <div className="destinationInfo text-end">
                              <div className="time">
                                <strong>
                                  {(item.flightSegments[item.flightSegments.length-1].arrivalDateTime === '0001-01-01T00:00:00')?"-- : --": moment(
                                    item.flightSegments[
                                      item.flightSegments.length - 1
                                    ].arrivalDateTime
                                  ).format("HH:mm")}
                                </strong>
                              </div>
                              <div className="codeCity">
                                {
                                  item.flightSegments[
                                    item.flightSegments.length - 1
                                  ].arrivalAirport.locationCode
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            
            <div className="hotel-head-info border-top" dir="ltr">
              {this.props.hotelModel.accommodation.mainPhoto == null ? (
                <div
                  className="hotel-image default-hotel-image"
                  style={{ backgroundImage: "url(" + HotelNoImage + ")" }}
                ></div>
              ) : (
                <div
                  className="hotel-image  main-hotel-image"
                  style={{
                    backgroundImage: `url(${this.props.hotelModel.accommodation.mainPhoto})`,
                  }}
                ></div>
              )}
              <div className="full-width">
                <h5 className="hotel-title en-font">
                  {this.props.hotelModel.accommodation.name}
                </h5>
                <div className="hotelRange">
                  {this.props.hotelModel.accommodation.rating ? (
                    <Rate number={this.props.hotelModel.accommodation.rating} />
                  ) : null}
                </div>
                <div className="gray font-12">
                  {this.props.hotelModel.accommodation.address}
                </div>
              </div>
            </div>

            <div className="pair-row">
              <span>{Dictionary.checkIn}</span>
              <Tooltip placement="top" title={Dictionary.propertyLocalTime}>
                <div dir="ltr">
                  <span className="semi-bold">
                    {this.props.hotelModel.date.checkIn.split("T")[0]}
                  </span>
                  <span className="itours-icon icon-small info-icon" />
                </div>
              </Tooltip>
            </div>

            <div className="pair-row">
              <span>{Dictionary.checkOut}</span>
              <Tooltip placement="top" title={Dictionary.propertyLocalTime}>
                <div dir="ltr">
                  <span className="semi-bold">
                    {this.props.hotelModel.date.checkOut.split("T")[0]}
                  </span>
                  <span className="itours-icon icon-small info-icon" />
                </div>
              </Tooltip>
            </div>

            <div className="pair-row">
              <span>{Dictionary.lengthOfStay}</span>
              <span className="semi-bold">
                {this.props.night} {Dictionary.nights}{" "}
              </span>
            </div>            

            {this.props.hotelModel.accommodation.rooms.map((item, index) => {
              return (
                <div
                  className="clearfix room-item font-14 margin-bottom-light border-top"
                  key={index}
                >
                  <div className="margin-bottom-light">
                    <div>
                      <span className="itours-icon hotel-dark margin-right-light icon-small" />
                      <span className="semi-bold"> {item.name} </span>
                    </div>
                    <ul>
                      <li>{item.board.name}</li>
                    </ul>

                    <div className="semi-bold">
                      (
                      <span className="inline-block">
                        {item.adults}
                        {item.adults > 1 ? Dictionary.adults : Dictionary.adult}
                      </span>
                      {item.children > 0 ? (
                        <span className="inline-block">
                          <span className="inline-block margin-end-light">
                            {Dictionary.comma}
                          </span>
                          {item.children}{" "}
                          {item.children > 1
                            ? Dictionary.children
                            : Dictionary.child}{" "}
                        </span>
                      ) : null}
                      )
                    </div>
                  </div>
                  {/*<div className={isStyle3?"golden":"green"}><span className="semi-bold"><Price currency={this.props.currencyCode}>{item.salePrice}</Price></span></div>*/}
                </div>
              );
            })} 

            {this.props.cancellationPolicy ? (
              <div className="border-top">
                <div>
                  <div className="margin-bottom-light margin-top-light">
                    {this.props.cancellationPolicy.status === "Refundable" ? (
                      <Tag color={"green"}>{Dictionary.refundable}</Tag>
                    ) : this.props.cancellationPolicy.status ===
                      "NonRefundable" ? (
                      <Tag color={"red"}>{Dictionary.nonRefundable}</Tag>
                    ) : (
                      <Tag>{this.props.cancellationPolicy.status}</Tag>
                    )}
                  </div>

                  {this.props.cancellationPolicy.fees.length > 0 ? (
                    <>
                    {LAN === "IR" && <div className="margin-bottom">
                        امکان کنسل کردن فقط تا تاریخ های زیر میسر است و در
                        صورت درخواست کنسلی، 
                        <b> ده درصد مبلغ (حداقل  2,000,000 ریال) </b>
                        بابت کارمزد خدمات از مبلغ کل رزرو کسر میگردد.
                    </div>}                                        
                      <ul className="no-margin ">
                        {this.props.cancellationPolicy.fees.map((rule, index) => {
                          if (rule.amount > 0) {
                            return (
                              <li key={index}>
                                <span className="inline-block margin-end-light">
                                  <Price currency={this.props.currencyCode}>
                                    {rule.amount}
                                  </Price>
                                </span>
                                <span className="inline-block margin-end-light">
                                  {Dictionary.afterDate}
                                </span>
                                <span className="inline-block  margin-end-light">
                                  <Tooltip
                                    title={
                                      <span dir="ltr" className="en-font">
                                        {moment(rule.fromDate).format(
                                          "DD MMMM YYYY"
                                        )}
                                      </span>
                                    }
                                  >
                                    <div className="inline-block">
                                      {moment(rule.fromDate).format("D/M/YYYY")}
                                    </div>
                                  </Tooltip>
                                </span>
                                {LAN === "IR" && (
                                  <span className="inline-block">
                                    ({moment(rule.fromDate).format("jYYYY/jM/jD")})
                                  </span>
                                )}
                              </li>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </ul>
                    </>
                  ) : (
                    <p>{Dictionary.ThereIsNoCancellationRule}</p>
                  )}
                </div>
              </div>
            ) : null}                     
          
            <div className="border-top">
              <div className="clearfix">
                <span className="pull-start font-14">
                  {Dictionary.flightPrice}
                </span>
                <b className="pull-end semi-bold" dir="ltr">
                  <Price currency={this.props.currencyCode}>
                    {this.props.flightPrice}
                  </Price>
                </b>
              </div>
              <div className="clearfix margin-top-half">
                <span className="pull-start font-14">
                  {Dictionary.hotelPrice}
                </span>
                <b className="pull-end semi-bold" dir="ltr">
                  <Price currency={this.props.currencyCode}>
                    {this.props.hotelModel.salePrice}
                  </Price>
                </b>
              </div>
              <div className="clearfix margin-top-half">
                <span className="pull-start font-18">
                  {Dictionary.totalPrice}
                </span>
                <b className="pull-end semi-bold font-18" dir="ltr">
                  <Price currency={this.props.currencyCode}>
                    {this.props.hotelModel.salePrice + this.props.flightPrice}
                  </Price>
                </b>
              </div>
              {this.props.showButton && (
                <div className="confirmButton">
                  <button className={`button ${isStyle3?"yellow-btn pale":"red-btn"} full-width has-arrow thick-btn`} type="submit">
                    {Dictionary.continueToPayment}
                  </button>
                </div>
              )}
            </div>          
          </div>

        <div
          className={
            this.state.showItinerariModal
              ? "itours-modal visible"
              : "itours-modal"
          }
        >
          <div className="modal-overlay" onClick={this.closeItinerariModal} />
          <div className="modal-inner">
            <div className="modal-header">{Dictionary.flightDetails}</div>
            <div className="modal-main">
              <div className="flightDetails">
                <div className="detail-flight">
                    <FlightItinerary model={this.props.flightModel} />               
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="button color-1-btn"
                onClick={this.closeItinerariModal}
              >
                {Dictionary.ok}
              </button>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
export default connect(mapStateToProps)(PackageSummary);