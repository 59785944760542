import React from "react";
import { Link } from "react-router-dom";
import Autocomplete from "../Common/Autocomplete.js";
import { ServerAddress, Hotel, Header } from "../../Enum/Urls";
import SelectRoom from "./SelectRoom";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import moment from "moment-jalaali";
import MultiDatePicker from "react-multi-date-picker";
import { connect } from "react-redux";
import SearchBoxTab from '../Common/SearchBoxTab';
import LayoutConfig from "../../Config/layoutConfig.js";
import { createDatePickerConfig } from "../../helpers/helpers.js";
import { CountryCode } from "../../Enum/Models.js";

/* import flightIcon from '../../image/flight.svg';
import hotelIcon from '../../image/hotel.svg'; */
class SearchForm extends React.Component {
  state = {
    isMobile:false,
    searchValidator: {
      location: true,
      dates: true,
    },
    validation: false,
    searchParam: {
      locationId: "",
      location: "",
      nationality: process.env.REACT_APP_SERVER_LOCALSERVER === "itours.ir" ? "IR" : process.env.REACT_APP_THEME=== "AYANAIRCOM" ? "IQ" : this.props.IPLocation || this.props.LAN === 'IR' ? 'IR' : this.props.LAN === "TR" ? "TR" : 'NO',
      searchType: "",
      adults: [2],
      children: [0],
      ages: [0],
      checkin: null,
      checkout: null,
    },
    searchMode: false,
    calendarIsGregorian: false,
    selectPassengersOptionVisibility: false,
    roomOptionSelect: "2ADT",
    datepickerType: this.props.LAN === "IR" ? "persian":"gregorian",
    datepickerLocale:this.props.LAN === "IR" ? "fa": this.props.LAN === "AR"? "ar":"en"
  };
  selectPassengersOptions = (event) => {
    let option = event.target.value;
    if (option === "2") {
      this.setState({
        searchParam: {
          ...this.state.searchParam,
          adults: [2],
          children: [0],
          ages: [0],
        },
        roomOptionSelect:"2ADT",
        selectPassengersOptionVisibility: false,
      });
    } else if (option === "1") {
      this.setState({
        searchParam: {
          ...this.state.searchParam,
          adults: [1],
          children: [0],
          ages: [0],
        },
        roomOptionSelect:"1ADT",
        selectPassengersOptionVisibility: false,
      });
    } else {
      this.setState({
        selectPassengersOptionVisibility: true,
        roomOptionSelect:"OPTIONS"
      });
    }
  };
  fillFromURL() {
    if (this.props.location.search !== "") {
      this.setState({ calendarIsGregorian: true });
      let parameters = queryString.parse(this.props.location.search);
      if (this.props.location.pathname === "/hotel/details") {
        this.setState(
          {
            searchParam: {
              ...parameters,
              locationId: this.props.hotelNameId?.id,
              location: this.props.hotelNameId?.name,
              searchType : 6,
              checkin: parameters.Checkin,
              checkout: parameters.Checkout,
              adults: parameters.Adults.split(",").map((item) => Number(item)),
              children: parameters.Children.split(",").map((item) =>
                Number(item)
              ),
              ages: parameters.Ages
                ? parameters.Ages.split(",").map((item) => Number(item))
                : []
            },
            searchMode:this.props.collapseMode?false:true,
          },
          () => {
            this.checkSelectedPassengers();
          }
        );
      } else {

        let nationality = this.props.IPLocation || this.props.LAN === 'IR' ? 'IR' : this.props.LAN === "TR" ? "TR" : 'NO';
        if (process.env.REACT_APP_SERVER_LOCALSERVER === "itours.ir"){
          nationality = "IR";
        }
        if(process.env.REACT_APP_THEME=== "AYANAIRCOM"){
          nationality = "IQ";
        }

        if(parameters.nationality){
          nationality = parameters.nationality
        }

        this.setState(
          {
            searchParam: {
              ...parameters,
              checkin: parameters.checkin,
              checkout: parameters.checkout,
              adults: parameters.adults.split(",").map((item) => Number(item)),
              children: parameters.children
                .split(",")
                .map((item) => Number(item)),
              ages: parameters.ages
                ? parameters.ages.split(",").map((item) => Number(item))
                : [],
                locationId: parameters.locationId,
                location: parameters.location,
                nationality : nationality
            },
            searchMode:this.props.collapseMode?false:true,
          },
          () => {
            this.checkSelectedPassengers();
          }
        );
      }
    }else{
      
      const isItoursIr = process.env.REACT_APP_SERVER_LOCALSERVER === "itours.ir";

      this.setState({
        searchMode:this.props.collapseMode?false:true,
        searchParam: {
          ...this.state.searchParam,
          checkin: isItoursIr? moment().add(1, "day").format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
          checkout: isItoursIr ? moment().add(2, "day").format("YYYY-MM-DD") : moment().add(1, "day").format("YYYY-MM-DD"),
        },
      })
    }
  }
  checkSelectedPassengers() {
    if (
      this.state.searchParam.adults.length > 1 ||
      this.state.searchParam.children[0] > 0
    ) {
      this.setState({
        roomOptionSelect: "OPTIONS",
        selectPassengersOptionVisibility: true,
      });
    } else {
      if (this.state.searchParam.adults[0] === 1) {
        this.setState({
          roomOptionSelect: "1ADT",
          selectPassengersOptionVisibility: false,
        });
      } else if (this.state.searchParam.adults[0] === 2) {
        this.setState({
          roomOptionSelect: "2ADT",
          selectPassengersOptionVisibility: false,
        });
      } else {
        this.setState({
          roomOptionSelect: "OPTIONS",
          selectPassengersOptionVisibility: true,
        });
      }
    }
  }
  componentDidMount() {
    if (window.innerWidth <= 767){
      this.setState({isMobile:true});
    }
    this.fillFromURL();
  }
  changeCalendarType = () => {
    this.setState((prevState) => {
      return { calendarIsGregorian: !prevState.calendarIsGregorian };
    });
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.fillFromURL();
    }
    if(prevProps.LAN !== this.props.LAN){
      this.setState({
        datepickerType: this.props.LAN === "IR" ? "persian":"gregorian",
        datepickerLocale:this.props.LAN === "IR" ? "fa": this.props.LAN === "AR"? "ar":"en"
      })
    }
  }
  disabledDate = (index, current) => {
    // Can not select days before today and today

    if (index === "checkout") {
      return current && current <= moment(this.state.searchParam.checkin);
    } else {
      return current && current <= moment().endOf("day").subtract(1, "day");
    }
  };
  resultRef = () => {
    console.log(this.myRef);
  };
  defaultDay = new Date();
  toggleSearchMode = () => {
    this.setState((prevState) => ({
      searchMode: !prevState.searchMode,
    }));
  };
  handleClick = () => {
    this.setState({
      validation: true,
    });
    if (this.state.searchParam.locationId && this.state.searchParam.checkin && this.state.searchParam.checkout) {
      if (this.state.searchParam.searchType === 6) {
        this.props.history.push(
          `/hotel/details?Id=${this.state.searchParam.locationId}&Adults=${this.state.searchParam.adults}&Children=${this.state.searchParam.children}&Ages=${this.state.searchParam.ages}&Checkin=${this.state.searchParam.checkin}&Checkout=${this.state.searchParam.checkout}`
        );
      } else {
        let targetUrl = `/hotel/result?locationId=${this.state.searchParam.locationId}&location=${this.state.searchParam.location}&checkin=${this.state.searchParam.checkin}&checkout=${this.state.searchParam.checkout}&adults=${this.state.searchParam.adults}&children=${this.state.searchParam.children}&ages=${this.state.searchParam.ages}`;
        if(process.env.REACT_APP_SERVER_LOCALSERVER === "itours.ir"){
          targetUrl += `&nationality=${this.state.searchParam.nationality}`
        }
        this.props.history.push(
          targetUrl
        );
      }
    }
  };
  handlePassenger = (e) => {
    let passenger = JSON.stringify(this.state.searchParam);
    passenger = JSON.parse(passenger);
    passenger.adults = e.adult;
    passenger.children = e.children;
    passenger.ages = e.age;
    this.setState({
      searchParam: { ...this.state.searchParam, ...passenger },
    });
  };

  toggleDatepicker = () =>{
    if (this.props.LAN ==="IR"){
      if(this.state.datepickerType === "gregorian"){
        this.setState({
          datepickerType:"persian",
          datepickerLocale:"fa"
        })
      }else{
        this.setState({
          datepickerType:"gregorian",
          datepickerLocale:"en"
        })
      }
    }else{
      debugger;
    }
  }

  getCallback = (id, text, type) => {
    let searchParamCatch = this.state.searchParam;
    searchParamCatch.locationId = id;
    searchParamCatch.location = text;
    searchParamCatch.searchType = type;
    this.setState({
      searchParam: { ...this.state.searchParam, ...searchParamCatch },
    });
  };

  checkinChangeHandle = (d) => {
    const e = d.toDate();
    let checkoutDate = this.state.searchParam.checkout;
    if(checkoutDate &&  moment(checkoutDate).isAfter(e)){
      this.setState(prevState=>({
        searchParam: {
          ...prevState.searchParam,
          checkin: moment(e).format('YYYY-MM-DD')
        },
      }),
      () => {
        this.checkinRef.closeCalendar();
      }
    );
    }else{
      this.setState(prevState=>({
          searchParam: {
            ...prevState.searchParam,
            checkin: moment(e).format('YYYY-MM-DD'),
            checkout: moment(e).add(1, 'day').format('YYYY-MM-DD'),
          },
        }),
        () => {
          this.checkinRef.closeCalendar();
          this.checkoutRef.openCalendar();
        }
      );
    }
  }

  render() {
    const { Dictionary, LAN } = this.props;

    const {searchParam} = this.state;

    const datepickerConfig = createDatePickerConfig(LAN, this.state.datepickerLocale, this.state.isMobile);
    
    let nights;
    if (searchParam.checkout && searchParam.checkin){
      nights = moment(searchParam.checkout).diff(moment(searchParam.checkin),"days");
    }

    let minCheckin = this.state.datepickerType === 'persian' ? moment().format('jYYYY/jMM/jDD') : moment().format('YYYY/MM/DD');
    const isItoursIr = process.env.REACT_APP_SERVER_LOCALSERVER === "itours.ir";
    if (isItoursIr) {
      minCheckin = this.state.datepickerType === 'persian' ? moment().add(1, 'day').format('jYYYY/jMM/jDD') : moment().add(1, 'day').format('YYYY/MM/DD');
    }

    const minCheckout = this.state.datepickerType === 'persian' ? moment(this.state.searchParam.checkin).add(1, 'day').format('jYYYY/jMM/jDD') : moment(this.state.searchParam.checkin).add(1, 'day').format('YYYY/MM/DD');
    
    return (
      <>
        <div
          className={`search-box-holder foreign-hotel-search clearfix ${this.props.collapseMode?"inline":""}`}>
          <div>
            {this.state.searchMode ? (
              <>
                {this.props.fixedSearchHolder ? <div className="fixed-search-overlay" onClick={this.toggleSearchMode} /> : null}
                <div className={this.props.fixedSearchHolder ? 'fixed-search-holder' : "not-fixed-search-holder"}>
                  {LayoutConfig.themeClassName === 'style-2' && !this.props.noTabs && <SearchBoxTab searchType="hotel" />}
                  <div className="searchbox-inner">

                    {this.props.isHome && LayoutConfig.themeClassName === 'style-3' && (
                      <div className="margin-bottom-half">
                          <Link to="/domesticHotel" className="style3-type-radio margin-end"> <span className="radio-mark"/> {Dictionary.domestic} </Link>
                          <Link to="/hotel" className="style3-type-radio active" > <span className="radio-mark"/> {Dictionary.foreign} </Link>
                      </div>
                    )}
                    
                    <div className="searchBar hotel">
                      {this.props.collapseMode && (
                        <>
                          {this.state.searchMode && (
                            <div className="clearfix">
                              <button type="button" className={'button show-close-btn pull-end'} onClick={this.toggleSearchMode}>
                                <span className="itours-icon gray-close-icon icon-large" />
                              </button>
                            </div>
                          )}
                        </>
                      )}
                      <div className="hotel-search-form-holder">
                        <div className="hotel-search-form-main">
                          <div className={`float-row ${LayoutConfig.themeClassName==="style-3"?"":"half-gap"}`}>
                            <div className={`col-small-12 ${isItoursIr?"col-medium-5":"col-medium-3 col-large-4"}`}>
                              <div className="form-group hotel-destination-holder relative">
                                {!!isItoursIr && <label className="font-13"> مقصد </label>}
                                <Autocomplete
                                  defaultText={this.state.searchParam.location}
                                  defaultValue={this.state.searchParam.locationId}
                                  validate={this.state.searchValidator.location}
                                  getValue={this.getCallback.bind(this)}
                                  placeholder={Dictionary.enterYourDestination}
                                  url={ServerAddress.Type + ServerAddress.Hotel + Hotel.GetLocation}
                                  min={3}
                                  name="sample"
                                  param="value"
                                  header={{
                                    ...Header ,
                                    "Accept-Language" : LAN==="IR" ? "fa-IR" : "en-US"
                                }}                                  
                                />
                                {this.state.validation && !this.state.searchParam.location && (
                                  <b className="validator-label">{Dictionary.pleaseEnterDestination}</b>
                                )}
                              </div>
                            </div>
                            <div className={`col-small-12 ${LayoutConfig.themeClassName === 'style-3'?"col-medium-7":"col-medium-6 col-large-5"}`}>
                            {!!isItoursIr && <label className="font-13"> تاریخ ورود و خروج </label>}
                              <div className="hotel-dates-group">
                                <div className="checkin-column">
                                  <div className="relative">
                                    <MultiDatePicker
                                      fixMainPosition={process.env.REACT_APP_THEME ==='ITOURS_IR'}
                                      ref={element => {this.checkinRef = element}}
                                      inputMode="none"
                                      calendarPosition={datepickerConfig.calendarPosition}
                                      weekDays={datepickerConfig.weekDays}
                                      digits={datepickerConfig.digits}
                                      inputClass="multi-datepicker-input"
                                      className={datepickerConfig.className}
                                      minDate={minCheckin}
                                      format={'YYYY-MM-DD'}
                                      calendar={this.state.datepickerType}
                                      locale={this.state.datepickerLocale}
                                      onFocusedDateChange={this.checkinChangeHandle}
                                      value={
                                        this.state.datepickerType === 'persian'
                                          ? moment(this.state.searchParam.checkin, 'YYYY/MM/DD').format('jYYYY/jMM/jDD')
                                          : this.state.searchParam.checkin
                                      }>
                                      {this.state.isMobile && (
                                        <div className="date-picker-title">{Dictionary.checkinDateSelection}</div>
                                      )}                                        
                                      {LAN === 'IR' && (
                                        <button
                                          type="button"
                                          className="button blue-btn small-btn margin-bottom-half margin-top-half fa-font"
                                          onClick={this.toggleDatepicker}>
                                          {this.state.datepickerLocale === 'fa' ? 'تقویم میلادی' : 'تقویم شمسی'}
                                        </button>
                                      )}
                                      {this.state.isMobile && (
                                        <button className={'close-datepicker-btn'} onClick={() => this.checkinRef.closeCalendar()}>
                                          <span className={`itours-icon gray-close-icon icon-large`} />
                                        </button>
                                      )}
                                    </MultiDatePicker>

                                    {this.state.validation && !this.state.searchParam.checkin && (
                                      <b className="validator-label">{Dictionary.pleaseSelectCheckinDate}</b>
                                    )}
                                  </div>
                                </div>
                                <div className="checkout-column">
                                  <div className="relative">
                                    <MultiDatePicker
                                      fixMainPosition={process.env.REACT_APP_THEME ==='ITOURS_IR'}
                                      ref={element => {this.checkoutRef = element}}
                                      onFocusedDateChange={(d) => {
                                        const e = d.toDate();
                                        this.setState(
                                          {
                                            searchParam: {
                                              ...this.state.searchParam,
                                              checkout: moment(e).format('YYYY-MM-DD'),
                                            },
                                          },
                                          () => {
                                            this.checkoutRef.closeCalendar();
                                          }
                                        );
                                      }}
                                      inputMode="none"
                                      calendarPosition={datepickerConfig.calendarPosition}
                                      weekDays={datepickerConfig.weekDays}
                                      digits={datepickerConfig.digits}
                                      inputClass="multi-datepicker-input"
                                      className={datepickerConfig.className}
                                      minDate={minCheckout}
                                      format={'YYYY-MM-DD'}
                                      calendar={this.state.datepickerType}
                                      locale={this.state.datepickerLocale}
                                      value={
                                        this.state.datepickerType === 'persian'
                                          ? moment(this.state.searchParam.checkout, 'YYYY/MM/DD').format('jYYYY/jMM/jDD')
                                          : this.state.searchParam.checkout
                                      }>
                                      {this.state.isMobile && (
                                        <div className="date-picker-title">{Dictionary.checkoutDateSelection}</div>
                                      )}                                         
                                      {LAN === 'IR' && (
                                        <button
                                          type="button"
                                          className="button blue-btn small-btn margin-bottom-half margin-top-half fa-font"
                                          onClick={this.toggleDatepicker}>
                                          {this.state.datepickerLocale === 'fa' ? 'تقویم میلادی' : 'تقویم شمسی'}
                                        </button>
                                      )}
                                      {this.state.isMobile && (
                                        <button className={'close-datepicker-btn'} onClick={() => this.checkoutRef.closeCalendar()}>
                                          <span className={`itours-icon gray-close-icon icon-large`} />
                                        </button>
                                      )}
                                    </MultiDatePicker>

                                    {this.state.validation && !this.state.searchParam.checkout && (
                                      <b className="validator-label">{Dictionary.pleaseSelectCheckoutDate}</b>
                                    )}
                                  </div>
                                </div>
                                <div className="nights-column">
                                  <div className="relative">
                                    <div className="relative">
                                      <span className="form-input nights-fake-input">{nights} {Dictionary.nights}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>


                            {!!isItoursIr && <div className="col-small-12 col-medium-5 form-group relative required">
                              <label className="font-13"> ملیت </label>
                              <select
                                title="ملیت"
                                className="form-select nationality-select"
                                name="nationality"
                                defaultValue={this.state.searchParam.nationality}
                                onChange={e =>{
                                  this.setState({
                                    searchParam: {
                                      ...this.state.searchParam,
                                      nationality:e.target.value
                                    }
                                  })
                                }}
                                >
                                {CountryCode.map((item) => (
                                  <option value={item.value} key={item.value}>
                                    {item.code}
                                  </option>
                                ))}
                              </select>
                            </div>}


                            <div className={`col-small-12 ${LayoutConfig.themeClassName === 'style-3'?"col-medium-7":"col-medium-3 col-large-3"}`}>
                              <div className="form-group">
                              {!!isItoursIr && <label className="font-13"> مسافران </label>}
                                <select
                                  className="form-select room-option-toggle-select"
                                  onChange={(event) => {
                                    this.selectPassengersOptions(event);
                                  }}>
                                  <option value="2" selected={this.state.roomOptionSelect === '2ADT'}>
                                    1 {Dictionary.room}
                                    {Dictionary.comma} 2 {Dictionary.adults}
                                    {Dictionary.comma} 0 {Dictionary.children}
                                  </option>
                                  <option value="1" selected={this.state.roomOptionSelect === '1ADT'}>
                                    {' '}
                                    1 {Dictionary.room}
                                    {Dictionary.comma} 1 {Dictionary.adults}
                                    {Dictionary.comma} 0 {Dictionary.children}
                                  </option>
                                  <option value="0" selected={this.state.roomOptionSelect === 'OPTIONS'}>
                                    {this.state.roomOptionSelect === 'OPTIONS'
                                      ? this.state.searchParam.adults.length +
                                        ' ' +
                                        Dictionary.room +
                                        Dictionary.comma +
                                        ' ' +
                                        this.state.searchParam.adults.reduce((sum, item) => sum + item, 0) +
                                        ' ' +
                                        Dictionary.adults +
                                        Dictionary.comma +
                                        ' ' +
                                        this.state.searchParam.children.reduce((sum, item) => sum + item, 0) +
                                        ' ' +
                                        Dictionary.children
                                      : Dictionary.moreOptions}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        {this.state.selectPassengersOptionVisibility && (
                            <SelectRoom
                              onSelectItem={(e) => {
                                this.handlePassenger(e);
                              }}
                              defaultAdult={this.state.searchParam.adults}
                              defaultChildren={this.state.searchParam.children}
                              defaultAges={this.state.searchParam.ages}
                            />
                        )}
                        <div className="button-holder">
                          <button
                            className={`searchBtn hotel-search-btn button ${LayoutConfig.themeClassName === 'style-3'?"yellow-btn":"red-btn"} full-width ${LayoutConfig.themeClassName === 'ayanair-theme'?"uppercase":""}`}
                            type="button"
                            onClick={() => {
                              this.handleClick();
                            }}>
                            <span className={`itours-icon search-icon margin-end-light ${LayoutConfig.themeClassName === "style-3"?"mirror-rtl icon-small":""}`} />
                            {Dictionary.search}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              !!this.state.searchParam.checkin && (
                <>
                  <div className={'headerInformation'}>
                    <div className={'title'}>
                      <div className={'hidden-xsmall hidden-small'}>
                        <span className="itours-icon hotel-dark margin-end-light icon-small" /> {Dictionary.searchSummary}
                      </div>
                      {/*<div className={"hidden-medium hidden-large hidden-xlarge"}>*/}
                      {/*            searched condition*/}
                      {/*</div>*/}
                    </div>
                    <div className={'buttonContainer'}>
                      <button type="button" className={`button ${(LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme')?"namin-blue-btn":"color-1-btn"} pull-end`} onClick={this.toggleSearchMode}>
                        {Dictionary.modifySearch}
                      </button>
                    </div>
                  </div>
                  <div className="searched-information">
                    <div className={'fakeForm'}>
                      <div className="textbox">
                        <span className="itours-icon location-dark margin-end-light icon-small" /> {this.state.searchParam.location}
                      </div>
                      <div className="textbox">
                        <span className="itours-icon checkin-dark margin-end-light icon-small" />
                        <span className="inline-block margin-end-light"> {this.state.searchParam.checkin} </span>
                        <span className={'font-11 inline-block'}>
                          {' '}
                          {LAN === 'IR' && <>({moment(this.state.searchParam.checkin).format('jYYYY-jMM-jDD')})</>}
                        </span>
                      </div>
                      <div className="textbox">
                        <span className="itours-icon checkout-dark margin-end-light icon-small" />
                        <span className="inline-block margin-end-light">{this.state.searchParam.checkout}</span>
                        <span className={'font-11 inline-block'}>
                          {LAN === 'IR' && <>({moment(this.state.searchParam.checkout).format('jYYYY-jMM-jDD')})</>}
                        </span>
                      </div>
                      <div className="textbox">
                        <span className="itours-icon moon-dark margin-end-light icon-small" />{' '}
                        {moment(this.state.searchParam.checkout).diff(moment(this.state.searchParam.checkin), 'days')}
                      </div>
                      <div className="textbox">
                        <span className="itours-icon hotel-dark margin-end-light icon-small" /> {this.state.searchParam.adults.length.toString()}{' '}
                        {Dictionary.room}
                      </div>
                    </div>
                  </div>
                </>
              )
            )}
          </div>
        </div>
      </>
    );
  }
}
const RouterDOM = withRouter(SearchForm);
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
  IPLocation: state.VisualData.ipLocation
});
export default connect(mapStateToProps)(RouterDOM);
