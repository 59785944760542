import {default as timemoment} from "moment";

export const createDatePickerConfig = (lan, locale, isMobile) => {
  return {
    calendarPosition: lan === 'IR' || lan === 'AR' ? 'bottom-right' : 'bottom-left',
    weekDays:
      locale === 'fa'
        ? ['ش', 'ی', 'د', 'س', 'چ', 'پ', 'ج']
        : locale === 'ar'
        ? ['الأحد', 'الإثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت']
        : undefined,
    digits: locale === 'ar' ? ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'] : undefined,
    className: `multi-datepicker ${isMobile ? 'rmdp-mobile' : ''} ${lan === 'IR' && locale === 'en' ? 'en-font' : ''}`,
  };
};

export const calculateMinutes = time => {
  if (time.includes('.')){
      const days = time.split(".")[0];
      const timeArray = time.split(".")[1].split(":");
      return +days * 60 * 24 + +timeArray[0] * 60 + +timeArray[1];
  }else{
      return +time.split(":")[0] * 60 + +time.split(":")[1];
  }
} 



// { date: string; format?: "ddd dd mm" | "dd mm yyyy" | "yyyy/mm/dd" | "YYYY-MM-DD", locale?: string }
export const dateDiplayFormat = ({ date, format, locale }) => {

  if (!date) return "";

  const dateObject = new Date(date);

  const day = dateObject.toLocaleString(locale, { day: "numeric" });
  const weekDay = dateObject.toLocaleString(locale, { weekday: 'short' });
  const month = dateObject.toLocaleString(locale, { month: "long" });
  const day2digit = dateObject.toLocaleString(locale, { day: "2-digit" })
  const month2digit = dateObject.toLocaleString(locale, { month: "2-digit" });
  const year = dateObject.toLocaleString(locale, { year: "numeric" });

  if (format === "ddd dd mm") {
      return (`${weekDay} ${day} ${month}`)
  }

  if (format === "dd mm yyyy") {
      return (`${day} ${month} ${year}`)
  }

  if (format === "yyyy/mm/dd") {
    return (`${year}/${month2digit}/${day2digit}`)
  }

  if (format === "YYYY-MM-DD") {
      return (`${year}-${month2digit}-${day2digit}`)
  }

  return date;
}

export const displayDuration = duration => {

  let days = 0;
  let hours = 0;
  let minutes = 0;

  let duration2 = duration;

  if (!duration || duration === "00:00:00") return "-- : --";

  if(duration.includes('.')){
    days = duration.split('.')[0] || 0;
    duration2 = duration.split('.')[1];
  }

  hours = duration2.split(":")[0];
  minutes = duration2.split(":")[1];

  let segments = [];

  if (days && days !== "00"){
    segments.push(days + " d ");
  }

  segments.push(hours + " hrs ");

  segments.push(minutes + " m ");

  return segments.join( "  " );

}

export const showDuration = duration => {

  if(duration === "00:00:00") return null;
  
  if (duration.indexOf(".")>0) {
    return (timemoment(duration, "d.HH:mm:ss").format("d[day(s)] HH[h] mm[m]"))
  }

  return (  timemoment(duration, "HH:mm:ss").format("HH[h] mm[m]"));

}