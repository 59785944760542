import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import axios from "axios";
import { connect } from "react-redux";
import { Tooltip, Tag, Skeleton } from "antd";
import { Link } from "react-router-dom";
import queryString from "query-string";

import {
  Header,
  Hotel as HotelURL,
  ServerAddress
} from "../../Enum/Urls";
import HotelItemDetails from "./HotelItemDetails";
import Loading from "../Common/Loading";
import Rating from "../Common/Rating";
import HotelNoImage from "../../image/default-hotel-image.svg";
// import {toggleAccountWindow } from "../../Actions/VisualDataAction";
import LayoutConfig from '../../Config/layoutConfig';
import Price from "../Common/Price";
import Map from "../Common/Map";

const HotelItem = props => {

  // const [requiredLoginModalVisibility, setRequiredLoginModalVisibility] = useState(false);
  // const [naminTravelSelectedHotelURL, setNaminTravelSelectedHotelURL] = useState();

  const [detailsLoading, setDetailsLoading] = useState(false);
  const [hotelDetails, setHotelDetails] = useState();
  const [detailsModalVisiblility, setDetailsModalVisiblility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [mapModalVisibility,setMapModalVisibility] = useState(false);

  // useEffect(() => {
  //   if (props.userData && props.userData.isEmailConfirmed && naminTravelSelectedHotelURL) {
  //     props.history.push(naminTravelSelectedHotelURL);
  //   }
  // }, [props.userData]);

  // bookHotel = (url) => {
  //   setNaminTravelSelectedHotelURL(url);
  //   setRequiredLoginModalVisibilitysetRequiredLoginModalVisibility(true);

  //   //props.setNaminTravelSelectedFlight({key:url,extraBaggage:false});
  // }

  const openMap = () => {setMapModalVisibility(true);}
  const closeMap = () => {setMapModalVisibility(false);}

  const parameters = queryString.parse(props.location.search);

  const showHotelDetail = id => {

    setDetailsModalVisiblility(true);

    if (!hotelDetails) {
      setDetailsLoading(true);

      let params = new URLSearchParams();
      params.append("Id", id);
      parameters.adults.split(",").forEach((item) =>
        params.append("Adults", item)
      );
      parameters.children.split(",").forEach((item) =>
        params.append("Children", item)
      );
      if (parameters.ages.length !== 0) {
        parameters.ages.split(",").forEach((item) => params.append("Ages", item));
      }
      params.append("Checkin", parameters.checkin);
      params.append("Checkout", parameters.checkout);

      axios({
        method: "get",
        url: `${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GetAccomadtionDetails}`,
        params: params,
        headers: { ...Header },
      })
        .then((response) => {
          setHotelDetails(response.data.result);
        })
        .catch((error) => {
          setErrorMessage("Internal Error,Please refresh the page");
        })
        .finally(() => {
          setDetailsLoading(false);
        });
    }
  }

  const {
    features,
    address,
    id,
    rating,
    mainPhoto,
    regularPrice,
    salePrice,
    discountPercent,
    name,
    coordinates
  } = props.model;

  const { Dictionary } = props;

  const detailBtn = <div className="text-end hotel-detail-btn-holder">
    <button type="button" disabled={detailsLoading} className="button link-style-btn" onClick={() => showHotelDetail(id)}>{Dictionary.hotelDetails}</button>
  </div>;

  const showOnMapBtn = <button type="button" className="button full-width show-on-map-btn margin-bottom-half" onClick={openMap}>
    {Dictionary.showOnMap}
  </button>;

  let detailUrl = `/hotel/details?Id=${id}&Adults=${parameters.adults}&Children=${parameters.children}&Ages=${parameters.ages}&Checkin=${parameters.checkin}&Checkout=${parameters.checkout}`;
  if (parameters.nationality){
      detailUrl+=`&nationality=${parameters.nationality}`
  } else if (process.env.REACT_APP_SERVER_LOCALSERVER === "itours.ir"){
    detailUrl+="&nationality=IR";
  }

  const selectHotelBtn = <>
    {/* {process.env.REACT_APP_THEME !=='NAMINTRAVELCOM%%%%%AYANAIRCOM' || (props.userData && props.userData.isEmailConfirmed) ? */}
    <Link
      className="button full-width red-btn has-arrow nowrap"
      target="_blank"
      to={detailUrl}
    >
      {Dictionary.seeRooms}
    </Link>
    {/* :<button
          type="button"
          className="button full-width red-btn has-arrow"
          onClick={()=>{bookHotel(detailUrl)}}
        >
          {Dictionary.seeRooms}
        </button>
      } */}
  </>;

  const priceLabel = <div className={"text-end font-12 gray price-for-night"}>
    <span>{Dictionary.startingPrice}</span> <span className="nowrap"> ({props.duration} {Dictionary.nights}) </span>
  </div>;

  const price = <>
    {discountPercent === 0 ? (
      <div className={"text-end prices-holder"}>
        <b
          className="purple small-margin-bottom text-center"
          dir="ltr"
        >
          <Price currency={props.currencyCode}>
            {salePrice}
          </Price>
        </b>
      </div>
    ) : (
      <div dir="ltr" className={"text-end prices-holder"}>
        <span className="margin-end-light old-price no-wrap inline-block">
          <Price currency={props.currencyCode}>
            {regularPrice}
          </Price>
        </span>
        <b className="purple small-margin-bottom text-center no-wrap inline-block">
          <Price currency={props.currencyCode}>
            {salePrice}
          </Price>
        </b>
      </div>
    )}
  </>;

  const mapPopopContent = (
    <div className="map-custom-popup-wrapper">
      <div className="popup-content">
        <div className="en-font" dir="ltr">
          <b>{name}</b> <Rating className="inline-block-middle" number={rating} />
          <div className="gray margin-top-light margin-bottom-half">{address}</div>
        </div>
        <Link
          className="button full-width red-btn nowrap"
          to={detailUrl}
        >
          {Dictionary.seeRooms}
        </Link>
      </div>
    </div>
  );


  return (
    <div className="hotel-result-item">

      {!!coordinates?.latitude && <div className={`itours-modal ${mapModalVisibility ? "visible" : ""}`} >
        <div className="modal-overlay" onClick={closeMap} />
        <div className="modal-inner">
          <div className="modal-header clearfix"> 
            <span
              onClick={closeMap}
              className="itours-icon gray-close-icon icon-large pull-right pointer"
            />
            <div className="padding-top-half" dir="ltr">
              <b>{name}</b> <Rating className="inline-block-middle" number={rating} /> 
            </div>
          </div>
          <div className="modal-main">
            <Map
              zoom={14}
              hotels={[{
                latitude: coordinates.latitude,
                longitude: coordinates.longitude,
                popupContent: mapPopopContent
              }]}
            />
          </div>
        </div>
      </div>}


      {/* {requiredLoginModalVisibility && <div className="itours-modal login-to-book-modal visible">
            <div className="modal-overlay" onClick={()=>{setRequiredLoginModalVisibility(false)}} />
            <div className="modal-inner small-width">
              <div className="modal-main">
                <span className="itours-icon user-login icon-xxlarge" />
                <h3 className="normal-title">{props.userData && props.userData.userName ? "please Confirm Your Email To Continue" :Dictionary.pleaseLoginToContinue} ...</h3>
                <p className="text-center gray semi-bold margin-bottom-large">
                  <span className="itours-icon info-icon icon-small margin-end-light" />
                  {props.userData && props.userData.userName ? Dictionary.pleaseConfirmYourEmailThenReloadThisPageToContinueTheBooking :Dictionary.toProceedWithBookingAtSpecialPriceYouMustLogIn}
                </p>
                <div className="text-center margin-top-large margin-bottom-large">
                  {props.userData && props.userData.userName ?
                  <button type="button" className="button blue-btn large-btn" onClick={()=>{window.location.reload();}} >
                    {Dictionary.Reload}
                  </button>
                  :<button
                    type="button"
                    className="button red-btn large-btn"
                    onClick={()=>{
                     setRequiredLoginModalVisibility(false);
                      props.toggleAccountWindow();
                    }}
                  >
                    {Dictionary.login}
                  </button>}               
                </div>
              </div>
            </div>
          </div>
        } */}

      {detailsModalVisiblility && <div className="itours-modal hotel-details-modal visible">
        <div className="modal-overlay" onClick={() => { setDetailsModalVisiblility(false) }} />
        <div className="modal-inner">
          <div className="modal-main">
            {detailsLoading ?
              <>
                {LayoutConfig.themeClassName === 'style-3' ? (
                  <div className="padding-mobile">
                    {/* <Loading fullPage description={Dictionary.getHotelInformation+" ..."}/> */}

                    <div className="justify-between margin-bottom padding-top" dir="ltr">
                      <Skeleton active paragraph={{ rows: 0 }} title={{ width: "70px" }} className="one-line-skeleton button-skeleton" />
                      <Skeleton active paragraph={{ rows: 0 }} className="one-line-skeleton text-right" />
                    </div>
                    <Skeleton active paragraph={{ rows: 0 }} title={{ width: "60%" }} className="one-line-skeleton text-left" />

                    <div className="box-border hotel-gallery-skeleton-wrapper margin-bottom margin-top" dir="ltr">
                      <div className="image-item" />

                      {[1, 2, 3, 4, 5, 6, 7, 8].map(item => (
                        <div className="image-item hidden-xsmall" key={item} />
                      ))}

                      <div className="facilities card-padding body-direction hidden-xsmall">
                        <Skeleton active />
                      </div>

                    </div>

                    <div className="box-border bg-white card-padding margin-bottom hidden-xsmall">
                      <Skeleton active paragraph={{ rows: 0 }} title={{ width: "70px" }} className="one-line-skeleton margin-bottom" />
                      <div className="bg-white justify-between margin-bottom-large">
                        {[1, 2, 3, 4].map(item => (
                          <div key={item} className="padding-v-5 grow">
                            <Skeleton key={item} active paragraph={{ rows: 0 }} className="one-line-skeleton" />
                          </div>
                        ))}
                      </div>

                      <Skeleton active paragraph={{ rows: 0 }} title={{ width: "70px" }} className="one-line-skeleton margin-bottom" />
                      <div className="bg-white justify-between">
                        {[1, 2, 3, 4].map(item => (
                          <div key={item} className="padding-v-5 grow">
                            <Skeleton key={item} active paragraph={{ rows: 0 }} className="one-line-skeleton" />
                          </div>
                        ))}
                      </div>
                      <div className="bg-white justify-between">
                        {[1, 2, 3, 4].map(item => (
                          <div key={item} className="padding-v-5 grow">
                            <Skeleton key={item} active paragraph={{ rows: 0 }} className="one-line-skeleton" />
                          </div>
                        ))}
                      </div>
                    </div>


                    <div className="box-border bg-white card-padding margin-bottom-large">
                      <Skeleton active className="text-left" />
                    </div>

                    <Skeleton active paragraph={{ rows: 0 }} title={{ width: "70px" }} className="one-line-skeleton margin-bottom" />

                    <br />

                  </div>
                ) : (
                  <div className="card-padding"><Loading /></div>
                )}
              </>
              : errorMessage ?
                <div className="card-padding text-center">
                  <br />
                  <span className="itours-icon error2-icon icon-xlarge" />
                  <br /><br />
                  <h3 className="normal-title">{errorMessage}</h3>
                  <br /><br />
                </div>
                :
                <HotelItemDetails hotelDetails={hotelDetails} />
            }
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="button grey-btn margin-end"
              onClick={() => { setDetailsModalVisiblility(false) }}
              disabled={detailsLoading}
            >
              {Dictionary.close}
            </button>
            {/* {process.env.REACT_APP_THEME !=='NAMINTRAVELCOM%%%%%AYANAIRCOM' || (props.userData && props.userData.isEmailConfirmed) ? */}
            <Link
              className="button red-btn has-arrow nowrap"
              to={detailUrl}
            >
              {Dictionary.seeRooms}
            </Link>
            {/* :<button
                  type="button"
                  className="button red-btn has-arrow"
                  onClick={()=>{bookHotel(detailUrl)}}
                >
                  {Dictionary.seeRooms}
                </button>
              } */}
          </div>
        </div>
      </div>}

      {mainPhoto == null ? (
        <div
          className="hotel-image default-image"
          style={{ backgroundImage: `url("${HotelNoImage}")` }}
        />
      ) : (
        <div className="hotel-image" style={{ backgroundImage: `url(${mainPhoto})` }} />
      )}

      <div className="hotel-content">
        <div className="hotel-details" dir="ltr">
          <div className="clearfix">

            {process.env.REACT_APP_THEME !== 'NAMINTRAVELCOM%%%%%AYANAIRCOM' || (props.userData && props.userData.isEmailConfirmed) ? (
              <Link
                className="inline-block hotel-name margin-right-light"
                target="_blank"
                to={detailUrl}
              >
                {name}
              </Link>
            ) : (
              <span className="inline-block hotel-name margin-right-light">{name}</span>
            )}

            <div className="rating inline-block">
              <Rating number={rating} />
            </div>

          </div>

          <div className="hotel-address">
            <span className="en-font gray font-12">{address}</span>
          </div>

          {props.model && props.model.boards && (props.model.boards.length > 0) ? (
            <div className="margin-top"> {Dictionary.board}: {props.model && props.model.boards && props.model.boards.map(boardItem => <Tooltip key={boardItem.name} placement="top" title={boardItem.name}>
              <Tag>{boardItem.code}</Tag>
            </Tooltip>)}
            </div>
          ) : null}

          <div className="feature-list">
            <div className="feature-items" dir="ltr">
              {features?.map((item, index) => (
                <div key={index} className="en-font">
                  {item.name}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="hotel-footer">
          <div className="result-footer-title full-width">
            {(LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme') ? (
              <div className="namin-travel-hotel-item-footer">
                {showOnMapBtn}
                {detailBtn}
                {priceLabel}
                {price}
                {selectHotelBtn}
              </div>
            ) : (
              <>
                {price}
                {priceLabel}
                {detailBtn}
                {showOnMapBtn}
                {selectHotelBtn}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const stateMapProps = (state) => {
  return {
    currencyCode: state.UserData.Currency,
    Dictionary: state.VisualData.Dictionary,
    LAN: state.VisualData.LAN,
    userData: state.UserData.userData
  };
};


export default withRouter(connect(stateMapProps)(HotelItem));