import React from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import moment from "moment-jalaali";
import { ServerAddress } from "../../Enum/Urls";
import {
  getBankList,
  setBankID,
  paymentByMethod,
  GetReserveById
} from "../../Actions/PaymentAction";
import { editMessage,setIpLocation,showError } from "../../Actions/VisualDataAction";
import {
  getReserveByID,
  bookhotel,
  selectedHotel,
  setSafarmarketHotelPixel
} from "../../Actions/HotelAction";
import {
  bankStatus,
  setFlightSelected,
  setSafarmarketPixel,
  setDirectTicketByIdResult,
  getTime
} from "../../Actions/FlightAction";
import RemainingTime from "../../components/Common/RemainingTime";
import paymentError from "../../image/payment-error.svg";
import { Steps } from "antd";
import { Payment } from "../../Enum/Models";
import FlighSummary from "../../components/Flight/FlighSummary";
import SummaryHotel from "../../components/Accomodation/SummaryHotel";
import PackageSummary from '../../components/Package/PackageSummary';
import PaymentList from "../../components/Common/PaymentList";
import Loading from "../../components/Common/Loading";
import Price from "../../components/Common/Price";
import LayoutConfig from "../../Config/layoutConfig";
import { Link } from "react-router-dom";
import PaymentByDeposit from "../../components/Common/PaymentByDeposit";
import DomesticHotelSummery from "../../components/domesticHotel/DomesticHotelSummery";
import StickySides from "../../components/Common/StickySides";
import { getIpLocation } from "../../Actions/UserAction";

class Reserve extends React.Component {
  state = {
    selectedBank: false,
    showError: false,
    isFlight: false,
    farePrice: null,
    reserveId:undefined,
    expirationTime:undefined,
    startTime:undefined,
    safarmarketSelectBank:false,
    paymentError:false,
    initialDepositOpen:false,
    username:null,
    detailsIsOpen:false,
    goTobankLoading:false,
    pageState:"selectBank",
    showStartHint: false
  };

  updateBankStatus = async(data)=>{
    const response = await bankStatus(data);
    return (response);
  }

  updateStartTime = async()=>{
    const response = await getTime();
    if (response.data?.result){
      this.setState({startTime:response.data.result})
    }
  }

  componentDidMount() {

    this.pageWrapper = React.createRef();

    document.addEventListener('visibilitychange', ()=>{
      if (document.visibilityState === "visible"){
        this.updateStartTime();
      }
    });
    this.props.setDirectTicketByIdResult(undefined);
    window.scrollTo(0, 0);
    this.props.editMessage(false);
    //
    let parameters = queryString.parse(this.props.location.search);
    
    let queryParams = this.props.location.search?.split("#")[0];
    const reserve_id = queryParams.split("reserveId=")[1]?.split("&")[0];
    const reserve_userName = queryParams.split("username=")[1]?.split("&")[0]; 
    this.setState({username:reserve_userName});
    this.setState({reserveId:reserve_id});
    //get payment
    const gatewayParams = {"reserveId": reserve_id,"username" : reserve_userName}
    if (this.props.LAN === "IR") {
      parameters.acceptLanguage = "fa-IR";
      gatewayParams.acceptLanguage = "fa-IR";
    }
    this.props.getBankList(gatewayParams);
    //get information
    this.props.setFlightSelected(null);
    this.props.selectedHotel(null);
    let params = new URLSearchParams();
    params.append("reserveId", reserve_id);
    reserve_userName && params.append("username", reserve_userName);
    this.props.GetReserveById(params, parameters);

    if(parameters.status === "0"){
      this.setState({pageState:"unsuccessful"});
    }else if(parameters.status === "1"){
      this.setState({pageState:"successful"});
    }else {
      this.setState({pageState:"selectBank", showStartHint:true})
    }

    let status = parseInt(parameters.status);
    if (parameters.mode && parameters.mode === "depositCharge"){
      this.setState({initialDepositOpen:true});
    }else{
      if (status > 0) {
        let param = new URLSearchParams();
        param.append("reserveId", reserve_id);
        reserve_userName && param.append("username", reserve_userName);
        const fetchData = async () => {
          this.props.paymentByMethod(params);
        };
        fetchData();
        
        
        // if(this.props.hotelInfo!==null){
        //     this.props.bookhotel({reserveId:parameters.reserveId,userName:parameters.username});
        // }else{
        //     let param=new URLSearchParams();
        //     param.append("reserveId",parameters.reserveId);
        //     this.props.getDirectTicketByID(param)
        // }
        // this.props.history.push(
        //   `/profile/details?userName=${parameters.username}&reserveId=${parameters.reserveId}`
        // );
      } else if (status === 0) {
        if (parameters.smid && this.props.currencyCode==="IRR"){
          this.updateBankStatus({reserveId:+reserve_id,status:1});
        }
        this.setState({ showError: true,paymentError:true });
      } else {
        this.setState({ showError: false });
      }
    }

    const fetchIpLocation = async () => {
      try{
        const response = await getIpLocation();
        localStorage.setItem('ipLocation', response.data?.result?.alpha2);
        this.props.setIpLocation(response.data?.result?.alpha2);

      }catch (err){
        console.log("ipLocationError", err);  
      }
    }
    fetchIpLocation();
    
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.startTime !== this.state.startTime){
      console.log(this.state.startTime);
    }
    if (this.props.respond && this.state.selectedBank) {
      this.props.editMessage(false);
      window.location.href = `${ServerAddress.Type}${ServerAddress.Payment}/${this.props.LAN==="IR"?"fa":"en"}/Reserves/Payment/PaymentRequest?tokenId=${this.props.tokenId}`;
    }
    if (
      this.props.flightInfo !== null &&
      this.props.flightInfo !== prevProps.flightInfo
    ) {
      let perPrice = [];
      this.props.flightInfo.passengersFare.forEach((item) => {
        perPrice[item.passengerTypeQuantity.code] = {
          totalTaxes: item.fare.totalTaxes,
          totalFare: item.fare.totalFare,
          baseFare: item.fare.baseFare,
        };
      });
      let passengerInfo = this.props.flightInfo.passengers;
      passengerInfo.forEach((itemPass) => {
        itemPass["baseFare"] = perPrice[itemPass.code].baseFare;
        itemPass["totalTaxes"] = perPrice[itemPass.code].totalTaxes;
        itemPass["totalFare"] = perPrice[itemPass.code].totalFare;
      });
      let expTime = undefined;
      const duration = moment.utc(this.props.flightInfo.expirationTime).diff(this.props.flightInfo.currentTime || Date.now());
      if(this.props.flightInfo.reserveStatus === Payment.Pending ){
        if (this.props.flightInfo.paymentBeforePNR){
          expTime=this.props.flightInfo.expirationTime;
        }else if (duration > 20*60*1000){
          expTime=moment(this.props.flightInfo.currentTime || Date.now()).add(20, 'minutes').format();
        }
      }
      this.setState({
        farePrice: passengerInfo,
        expirationTime:expTime,
        startTime:this.props.flightInfo.currentTime
      });

      let parameters = queryString.parse(this.props.location.search);

      //priceChange message:
      if (this.props.flightInfo.priceChangedAtReserve && parameters.status!=="0" && parameters.status!=="1"){
        this.props.showError({
          visibility:true,
          content:<div>
              {this.props.Dictionary.ThePriceOfThisFlightHasChanged}.
              <br/>
              <h5 className="normal-title">{this.props.Dictionary.newPrice}: <span dir="ltr"><Price currency={this.props.currencyCode}>
                  {this.props.flightInfo.flightFare.totalFare}
                </Price></span>
              </h5>
            </div>,
          closable:true,
          buttonLabel:this.props.Dictionary.ok
        });
      }

    }
    if (
      (this.props.hotelInfo && this.props.hotelInfo !== prevProps.hotelInfo)
      ||
      (this.props.flightInfo && this.props.flightInfo !== prevProps.flightInfo)
    ){
      //safarmarket:
      const parameters = queryString.parse(this.props.location.search);
      if (parameters.smid && this.props.currencyCode==="IRR"){
        if (parseInt(parameters.status)===0){
          if (this.props.flightInfo){
            this.setSMPixelFlight(this.props.flightInfo,parameters.smid,5);
          }else if (this.props.hotelInfo){
            this.setSMPixelHotel(this.props.hotelInfo,parameters.smid,5);
          } else if (this.props.domesticHotelInfo){
            this.setSMPixelDomesticHotel(this.props.hotelInfo,parameters.smid,5);
          }
        }else if (parseInt(parameters.status)===1){
          if (this.props.flightInfo){
            this.setSMPixelFlight(this.props.flightInfo,parameters.smid,4);
          }else if (this.props.hotelInfo){
            this.setSMPixelHotel(this.props.hotelInfo,parameters.smid,4);
          }else if (this.props.domesticHotelInfo){
            this.setSMPixelDomesticHotel(this.props.hotelInfo,parameters.smid,4);
          }
        }else{
          this.setState({safarmarketSelectBank:true})
        }
      }
    }
    if(this.props.directTicketResult !== prevProps.directTicketResult ){ 
      this.scrollToTop();     
      this.props.editMessage(false);
      //
      let parameters = queryString.parse(this.props.location.search);
      if (this.props.LAN === "IR") {
        parameters.acceptLanguage = "fa-IR";
      }
      //update information
      this.props.setFlightSelected(null);
      let params = new URLSearchParams();

      let queryParams = this.props.location.search?.split("#")[0];
      const reserve_id = queryParams.split("reserveId=")[1]?.split("&")[0];
      const reserve_userName = queryParams.split("username=")[1]?.split("&")[0]; 

      params.append("reserveId", reserve_id);
      reserve_userName && params.append("username", reserve_userName);
      this.props.GetReserveById(params, parameters);

    }
  }

  componentWillUnmount(){
    this.props.setSafarmarketPixelValue(undefined);
    this.props.setSafarmarketHotelPixelValue(undefined);
  }
  
  bankSelect = async(id, param) => {
    this.setState({
      goTobankLoading:true
    });
    this.props.editMessage(false);
    this.setState({
      selectedBank: true,
    });
    let reserveID =this.props.location.search.split("reserveId=")[1].split("&")[0];
    let callbackUrl = `${ServerAddress.Type}${ServerAddress.localServer}/payment/reserve`;
    let parameters = queryString.parse(this.props.location.search);
    if (parameters.smid){
      callbackUrl += `?smid=${parameters.smid}`;
    }
    let params = {
      reserveId:+reserveID,
      gatewayId: id,
      callbackUrl: callbackUrl,
      ipAddress: this.props.ipLocation,
      formParams: { ...param },
    };
    if (this.state.safarmarketSelectBank){
      if (this.props.flightInfo){
        this.setSMPixelFlight(this.props.flightInfo,parameters.smid,3);
      }else if (this.props.hotelInfo){
        this.setSMPixelHotel(this.props.hotelInfo,parameters.smid,3);
      }else if (this.props.domesticHotelInfo){
        this.setSMPixelDomesticHotel(this.props.hotelInfo,parameters.smid,3);
      }
      const response = await bankStatus({reserveId:+parameters.reserveId,status:0});
      if (response.data && response.data.success){
        this.props.setBankID(params);
      }else{
        debugger;
      }
    }else{
      this.props.setBankID(params);
    }
  };
  phoneItem = (value)=>{
    if (LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme'){
      return (
        <a target="_blank" rel="noreferrer" href={`https://wa.me/${value.replace(/ +/g, "").replace(/[{(+)}]/g, '').replace(/-/g , "")}`} className="pull-end" dir="ltr"  >
        <span className="margin-end-half itours-icon whatsapp icon-small" />
          <span dir="ltr">{value} ({this.props.Dictionary.WhatsAppOnly})</span>
        </a>
      );
    }
    return (
      <span className="pull-end" dir="ltr" >
        <span className="margin-right-light itours-icon red-phon-icon icon-small" />
        <span dir="ltr">{value} </span>
      </span>
    )
  };

  setSMPixelFlight = (reserveInformation,smId,paymentStatus) =>{
    const pixel = {};
    const reserveId = queryString.parse(this.props.location.search).reserveId;
    const firstSegment = reserveInformation.airItinerary.originDestinationOptions[0]?.flightSegments[0];
    const segmentsLength = reserveInformation.airItinerary.originDestinationOptions[0]?.flightSegments?.length;
    const lastSegment = reserveInformation.airItinerary.originDestinationOptions[0]?.flightSegments[segmentsLength-1]; 
    //pixel.pnr = (!reserveInformation.pnrCode || reserveInformation.pnrCode.toLowerCase().includes("not set") )?reserveId:reserveInformation.pnrCode;
    pixel.pnr=reserveId;
    pixel.flight_number = firstSegment.flightNumber;
    pixel.fromCode = firstSegment.departureAirport.locationCode;
    pixel.toCode = lastSegment.arrivalAirport.locationCode;
    pixel.departureTime = moment(firstSegment.departureDateTime).format("YYYY-MM-DD HH:mm");
          
    let flightType="";
    if (reserveInformation.flightType && reserveInformation.flightType.toLowerCase() ==="systemic"){
      flightType="SYSTEM";
    }else if (reserveInformation.flightType && reserveInformation.flightType.toLowerCase() ==="charter"){
      flightType="CHARTER";
    }else if(reserveInformation.flightType){
      flightType = reserveInformation.flightType.toUpperCase();
    }
    pixel.flightType = flightType;

    if(reserveInformation.airTripType === "RoundTrip"){
      const returnFirstSegment = reserveInformation.airItinerary.originDestinationOptions[1]?.flightSegments[0];
      pixel.returnTime= moment(returnFirstSegment.departureDateTime).format("YYYY-MM-DD HH:mm");
      pixel.returnFlightType =flightType;
      pixel.returnFlightNumber = returnFirstSegment.flightNumber;
    }else{
      pixel.returnTime="";
      pixel.returnFlightType ="";
      pixel.returnFlightNumber = "";
    }

    pixel.adults = reserveInformation.passengers.filter(passenger=>passenger.code==="ADT").length;
    pixel.children = reserveInformation.passengers.filter(passenger=>passenger.code==="CHD").length;
    pixel.infants = reserveInformation.passengers.filter(passenger=>passenger.code==="INF").length;

    pixel.passengerName = reserveInformation.reserver.firstName + " " + reserveInformation.reserver.lastName;
    let passengerPhoneNumber= reserveInformation.reserver.phoneNumber;
    if (passengerPhoneNumber.toLowerCase().includes('0098')){
      passengerPhoneNumber = passengerPhoneNumber.replace("0098", "0");
    } else if(passengerPhoneNumber.toLowerCase().includes('+98')){
      passengerPhoneNumber = passengerPhoneNumber.replace("+98", "0");
    }
    if (passengerPhoneNumber.indexOf("0")!==0){
      passengerPhoneNumber = "0"+ passengerPhoneNumber;
    }
    pixel.passengerPhone = passengerPhoneNumber;
    pixel.passengerEmail = reserveInformation.reserver.email;

    pixel.oneAdultPrice = reserveInformation.passengersFare.filter(item => item.passengerTypeQuantity.code==="ADT")[0]?.fare.totalFare;
    pixel.totalPrice = reserveInformation.flightFare.totalFare;       
    
    this.props.setSafarmarketPixelValue(encodeURI(`https://safarmarket.com/api/v1/trace/pixel/itours/${paymentStatus}/?smId=${smId}&pnr=${pixel.pnr}&fliNo=${pixel.flight_number}&typ=${pixel.flightType}&pri=${pixel.oneAdultPrice}&pax=${pixel.totalPrice}&adu=${pixel.adults}&chi=${pixel.children}&inf=${pixel.infants}&from=${pixel.fromCode}&to=${pixel.toCode}&dep=${pixel.departureTime}&ret=${pixel.returnTime}&nam=${pixel.passengerName}&pho=${pixel.passengerPhone}&ema=${pixel.passengerEmail}&rFTyp=${pixel.returnFlightType}&rPnr=&rFliNo=${pixel.returnFlightNumber}&bck=false`));
  }
  setSMPixelHotel = (reserveInformation,smId,paymentStatus) =>{
    const adultsAll = reserveInformation.accommodation.rooms.reduce((adultsSum,roomItem) => adultsSum + roomItem.adults,0);
    const agesAll = reserveInformation.accommodation.rooms.reduce((agesArray,roomItem) => [...agesArray , ...roomItem.ages],[]);
    let passengerPhoneNumber= reserveInformation.reserver.phoneNumber;
    if (passengerPhoneNumber.toLowerCase().includes('0098')){
      passengerPhoneNumber = passengerPhoneNumber.replace("0098", "0");
    } else if(passengerPhoneNumber.toLowerCase().includes('+98')){
      passengerPhoneNumber = passengerPhoneNumber.replace("+98", "0");
    }
    if (passengerPhoneNumber.indexOf("0")!==0){
      passengerPhoneNumber = "0"+ passengerPhoneNumber;
    }
    const pixel = {
      adults : adultsAll,
      ages : agesAll,
      children : agesAll.length,
      guests : adultsAll + agesAll.length,
      rooms : reserveInformation.accommodation.rooms.length,
      cityId : reserveInformation.city.id,
      cityName : reserveInformation.city.name,
      //countryId : reserveInformation.country.code,
      countryId : reserveInformation.country.id,
      hotelName : reserveInformation.accommodation.name,
      rating : reserveInformation.accommodation.rating,
      price : reserveInformation.salePrice,
      reserverName : reserveInformation.reserver.firstName+" "+reserveInformation.reserver.lastName,
      passengerPhone : passengerPhoneNumber,
      passengerEmail : reserveInformation.reserver.email,
      checkin : moment(reserveInformation.date.checkIn).format("YYYY-MM-DD"),
      checkout: moment(reserveInformation.date.checkOut).format("YYYY-MM-DD"),
      reserveId:reserveInformation.reserveId
    };
    this.props.setSafarmarketHotelPixelValue(encodeURI(`https://safarmarket.com/api/hotel/v1/pixel/itours/${paymentStatus}/0/?smId=${smId}&PAX=${pixel.guests}&ROOM=${pixel.rooms}&ADL=${pixel.adults}&CHD=${pixel.children}&AGES=${pixel.ages.join(",")}&DSTID=${pixel.cityId}&CTY=${pixel.cityName}&CNTRYID=${pixel.countryId}&HNAME=${pixel.hotelName}&HEN=${pixel.hotelName}&STAR=${pixel.rating}&TOTPR=${pixel.price}&NAME=${pixel.reserverName}&PHON=${pixel.passengerPhone}&EMAIL=${pixel.passengerEmail}&CI=${pixel.checkin}&CO=${pixel.checkout}&REF=${pixel.reserveId}`));
  }

  setSMPixelDomesticHotel = (reserveInformation,smId,paymentStatus) =>{
    debugger;
    // const adultsAll = reserveInformation.accommodation.rooms.reduce((adultsSum,roomItem) => adultsSum + roomItem.adults,0);
    // const agesAll = reserveInformation.accommodation.rooms.reduce((agesArray,roomItem) => [...agesArray , ...roomItem.ages],[]);
    // let passengerPhoneNumber= reserveInformation.reserver.phoneNumber;
    // if (passengerPhoneNumber.toLowerCase().includes('0098')){
    //   passengerPhoneNumber = passengerPhoneNumber.replace("0098", "0");
    // } else if(passengerPhoneNumber.toLowerCase().includes('+98')){
    //   passengerPhoneNumber = passengerPhoneNumber.replace("+98", "0");
    // }
    // if (passengerPhoneNumber.indexOf("0")!==0){
    //   passengerPhoneNumber = "0"+ passengerPhoneNumber;
    // }
    // const pixel = {
    //   adults : adultsAll,
    //   ages : agesAll,
    //   children : agesAll.length,
    //   guests : adultsAll + agesAll.length,
    //   rooms : reserveInformation.accommodation.rooms.length,
    //   cityId : reserveInformation.city.id,
    //   cityName : reserveInformation.city.name,
    //   //countryId : reserveInformation.country.code,
    //   countryId : reserveInformation.country.id,
    //   hotelName : reserveInformation.accommodation.name,
    //   rating : reserveInformation.accommodation.rating,
    //   price : reserveInformation.salePrice,
    //   reserverName : reserveInformation.reserver.firstName+" "+reserveInformation.reserver.lastName,
    //   passengerPhone : passengerPhoneNumber,
    //   passengerEmail : reserveInformation.reserver.email,
    //   checkin : moment(reserveInformation.date.checkIn).format("YYYY-MM-DD"),
    //   checkout: moment(reserveInformation.date.checkOut).format("YYYY-MM-DD"),
    //   reserveId:reserveInformation.reserveId
    // };
    // this.props.setSafarmarketHotelPixelValue(encodeURI(`https://safarmarket.com/api/hotel/v1/pixel/itours/${paymentStatus}/0/?smId=${smId}&PAX=${pixel.guests}&ROOM=${pixel.rooms}&ADL=${pixel.adults}&CHD=${pixel.children}&AGES=${pixel.ages.join(",")}&DSTID=${pixel.cityId}&CTY=${pixel.cityName}&CNTRYID=${pixel.countryId}&HNAME=${pixel.hotelName}&HEN=${pixel.hotelName}&STAR=${pixel.rating}&TOTPR=${pixel.price}&NAME=${pixel.reserverName}&PHON=${pixel.passengerPhone}&EMAIL=${pixel.passengerEmail}&CI=${pixel.checkin}&CO=${pixel.checkout}&REF=${pixel.reserveId}`));
  }


  scrollToTop = () => { if(this.pageWrapper && this.pageWrapper.current ){ this.pageWrapper.current.scrollIntoView({behavior: "smooth"})}} ;
  

  render() {
    const { Dictionary, LAN } = this.props;
    // let checkIn=null;
    // let checkOut=null;
    let night = 0;
    let totalPrice = 0;
    const { respond, banks, hotelInfo, flightInfo ,packageInfo,directTicketResult,domesticHotelInfo,domesticHotelReserveInfo} = this.props;
    const { Step } = Steps;
    if (respond) {
      if (hotelInfo != null) {
        night = hotelInfo.date.duration;
        // let cashDateIN=new Date(hotelInfo.date.checkIn);
        // checkIn=cashDateIN.getFullYear()+"/"+(cashDateIN.getMonth()+1)+"/"+cashDateIN.getDate();
        // let cashDateOUT=new Date(hotelInfo.date.checkOut);
        // checkOut=cashDateOUT.getFullYear()+"/"+(cashDateOUT.getMonth()+1)+"/"+cashDateOUT.getDate();
        if (hotelInfo.accommodation) {
          hotelInfo.accommodation.rooms.forEach((item) => {
            totalPrice += parseFloat(item.salePrice);
          });
        }
      }
    }
    let statusBox = null;
    if (flightInfo){
      switch (flightInfo.reserveStatus){
        case Payment.Issued :
          statusBox = (<div className="green-card card card-padding message-block box-border margin-bottom" >
            <p className="semi-bold font-18">
              <span className="itours-icon check-green margin-end-half" />
              {LAN==="IR" && <span className="margin-end-light">{flightInfo.reserver.firstName} عزیز،</span>}
              {Dictionary.YourTicketHasBeenIssuedSuccessfully}.
            </p>
            <div className="clearfix">
              <a
              href={
                process.env.REACT_APP_THEME === "NAMINTRAVELCOM"?
                `https://voucher.namintravel.com/en/flight/Ticket2?reserveid=${this.state.reserveId}&username=${flightInfo.reserver.username}`
                : process.env.REACT_APP_THEME ==='AYANAIRCOM'?
                `https://voucher.ayanair.net/en/flight/ayanairTicket?reserveId=${this.state.reserveId}&username=${flightInfo.reserver.username}`
                : (LAN ==="IR")?
                `https://voucher.itours.ir/en/flight/irTicket3?reserveid=${this.state.reserveId}&username=${flightInfo.reserver.username}`
                :
                `https://voucher.imt.as/en/flight/Ticket?reserveid=${this.state.reserveId}&username=${flightInfo.reserver.username}`
              }
                target="_blank"
                rel="noopener noreferrer"
                download
                className="button color-1-btn pull-end"
              >
                {Dictionary.ticket}
              </a>
              {(process.env.REACT_APP_THEME === "NAMINTRAVELCOM" || process.env.REACT_APP_THEME === "AYANAIRCOM") && <a
                href={
                  process.env.REACT_APP_THEME === "NAMINTRAVELCOM"?
                  `https://voucher.namintravel.com/en/Factor/Flight?reserveId=${this.state.reserveId}&username=${flightInfo.reserver.username}`
                  :`https://voucher.ayanair.net/en/Factor/Flight?reserveId=${this.state.reserveId}&username=${flightInfo.reserver.username}`
                  }
                target="_blank"
                rel="noopener noreferrer"
                download
                className="button grey-btn pull-end margin-end-half"
              >
                {Dictionary.Invoice}
              </a>}               

            </div>
          </div>);
          break;
        case Payment.WebServiceUnsuccessful:
          statusBox = (<div className="red-card card card-padding message-block box-border margin-bottom clearfix" >
            <p className="semi-bold font-18">
              <span className="itours-icon circle-error margin-end-half" />
              {LAN==="IR" && <span className="margin-end-light">{flightInfo.reserver.firstName} عزیز،</span>}
              {Dictionary.SorryYourTicketWasNotIssued}. {Dictionary.PleaseContactOurSupportTeamBeforeTryingAgainForBooking}.
              {LAN==="IR" && "  از این که با شکیبایی خود ما را یاری میفرمایید بسیار سپاسگزاریم."}
            </p>
            <div className="semi-bold font-18 margin-bottom">{Dictionary.trackingNumber} : {this.state.reserveId}</div>
            <span className="inline-block">
              
              {LayoutConfig.themeClassName === 'naminTravel-theme'?
                Dictionary.ForQuestionsRegardingThisReservationPleaseContactNaminTravelDirectly
                :LayoutConfig.themeClassName === 'ayanair-theme'?
                Dictionary.ForQuestionsRegardingThisReservationPleaseContactAyanairDirectly
                :Dictionary.ForQuestionsRegardingThisReservationPleaseContactItoursDirectly
              }
              :
            </span>
            {LayoutConfig.contacts?.telephone?.length ? this.phoneItem (LayoutConfig.contacts.telephone[0])
            : this.phoneItem(Dictionary.supportNumber)}   
          </div>);
          break;
        case Payment.InProgress:
        case Payment.UnConfirmed:
          statusBox = (<div className="yellow-card card card-padding message-block box-border margin-bottom clearfix" >
            <p className="semi-bold font-16">
              <span className="itours-icon yellow-warning margin-end-half" />
              {LAN==="IR" && <span className="margin-end-light">{flightInfo.reserver.firstName} عزیز،</span>}
              {Dictionary.YourTicketHasBeenRegisteredAndOurSupportTeamIsIssuingItYourTicketWillBeSentToYouAsSoonAsItIsIssued}
            </p>  
            <div className="semi-bold font-18 margin-bottom">{Dictionary.trackingNumber} : {this.state.reserveId}</div>
            <span className="inline-block">
              {LayoutConfig.themeClassName === 'naminTravel-theme'?
                Dictionary.ForQuestionsRegardingThisReservationPleaseContactNaminTravelDirectly
                :LayoutConfig.themeClassName === 'ayanair-theme'?
                Dictionary.ForQuestionsRegardingThisReservationPleaseContactAyanairDirectly
                :Dictionary.ForQuestionsRegardingThisReservationPleaseContactItoursDirectly
              }
              :
            </span>
            {LayoutConfig.contacts?.telephone?.length ? this.phoneItem (LayoutConfig.contacts.telephone[0])
            : this.phoneItem(Dictionary.supportNumber)} 
          </div>);
          break;
        case Payment.Refunded:
        case Payment.PaidBack:
          statusBox = (<div className="green-card card card-padding message-block box-border margin-bottom clearfix" >
            <p className="semi-bold font-16">
              <span className="itours-icon check-green margin-end-half" /> {Dictionary.TheAmountWasCreditedToYourAccount}.
            </p>  
            <div className="semi-bold font-18 margin-bottom">{Dictionary.trackingNumber} : {this.state.reserveId}</div>
            <span className="inline-block">
              {LayoutConfig.themeClassName === 'naminTravel-theme'?
                Dictionary.ForQuestionsRegardingThisReservationPleaseContactNaminTravelDirectly
                :LayoutConfig.themeClassName === 'ayanair-theme'?
                Dictionary.ForQuestionsRegardingThisReservationPleaseContactAyanairDirectly
                :Dictionary.ForQuestionsRegardingThisReservationPleaseContactItoursDirectly
              }
              :
            </span>
            {LayoutConfig.contacts?.telephone?.length ? this.phoneItem (LayoutConfig.contacts.telephone[0])
            : this.phoneItem(Dictionary.supportNumber)} 
          </div>);
          break;
        default:
          statusBox = null;
      } 
    }

    if(hotelInfo && this.props.bookingUnconfirm){
      statusBox = <div className="yellow-card card card-padding message-block box-border margin-bottom clearfix" >
            <p className="semi-bold font-16">
              <span className="itours-icon yellow-warning margin-end-half" />
              {LAN==="IR" && <span className="margin-end-light">{hotelInfo.reserver.firstName} عزیز،</span>}
              {Dictionary.HotelUnconfirmStatusText}
            </p>  
            <div className="semi-bold font-18 margin-bottom">{Dictionary.trackingNumber} : {this.state.reserveId}</div>
            <span className="inline-block">
              {LayoutConfig.themeClassName === 'naminTravel-theme'?
                Dictionary.ForQuestionsRegardingThisReservationPleaseContactNaminTravelDirectly
                :LayoutConfig.themeClassName === 'ayanair-theme'?
                Dictionary.ForQuestionsRegardingThisReservationPleaseContactAyanairDirectly
                :Dictionary.ForQuestionsRegardingThisReservationPleaseContactItoursDirectly
              }
              :
            </span>
            {LayoutConfig.contacts?.telephone?.length ? this.phoneItem (LayoutConfig.contacts.telephone[0])
            : this.phoneItem(Dictionary.supportNumber)} 
          </div>
    }

    const AppAddress = process.env.REACT_APP_SERVER_LOCALSERVER; 
    const isItoursIr = LAN === 'IR' && AppAddress ==="itours.ir";
    const isStyle3 = LayoutConfig.themeClassName === 'style-3';
    
    let user_name;
    let detailsHint = null;
    if (this.props.hotelInfo){
      user_name = this.props.hotelInfo.reserver?.username;
    }
    if ( LAN === "IR" && (this.props.flightInfo || this.props.packageInfo)){
      detailsHint = <div className={`bg-white ${isStyle3?"blue-bordered-message-block":"message-block error-message"} box-border card-padding bold`}>
      <div className="font-18">
      برای مشاهده اطلاعات بار و قوانین پرواز روی
      <span className="summary-detail-link" onClick={() => {
        this.setState({
          detailsIsOpen: true
        })
      }}> جزییات پرواز </span>
      کلیک کنید.
    </div>
    </div>
  ;
    }
    if (this.props.flightInfo){
      user_name = this.props.flightInfo.reserver?.username;
    }
    if (this.props.packageInfo){
      user_name = packageInfo.reserveFlightInfo.reserver?.username
    }

    let reserveInformation, hotelInformation;

    if (domesticHotelInfo) {
      hotelInformation = {
        image: {
          url: domesticHotelInfo.ImageUrl,
          alt: domesticHotelInfo.ImageAlt,
          title: domesticHotelInfo.ImageTitle
        },
        name: `${domesticHotelInfo.HotelCategoryName} ${domesticHotelInfo.HotelName} ${domesticHotelInfo.CityName}`,
        rating: domesticHotelInfo.HotelRating,
        address: domesticHotelInfo.Address,
        TopSelling: domesticHotelInfo.TopSelling,
        Url: domesticHotelInfo.Url,
        CityId: domesticHotelInfo.CityId
      }
    }
    if (domesticHotelReserveInfo) {
      reserveInformation = {
        checkin: domesticHotelReserveInfo.checkin,
        checkout: domesticHotelReserveInfo.checkout,
        duration: domesticHotelReserveInfo.count,
        rooms: domesticHotelReserveInfo.rooms.map(roomItem => ({
          name: roomItem.name,
          board: roomItem.boardCode,
          cancellationPolicyStatus: roomItem.cancellationPolicyStatus,
          bed: roomItem.bed,
          extraBed: roomItem.extraBed,
          pricing: roomItem.pricing,
          maxChildAge: roomItem.maxChildAge,
          maxInfantAge: roomItem.maxInfantAge
        })),
        salePrice: domesticHotelReserveInfo.totalPrice,
        boardPrice: domesticHotelReserveInfo.totalBoardPrice
      }
    }

    let loadingMessage = "";
    if(this.props.directTicketResult){
      loadingMessage = Dictionary.loadingData;
    }else if (this.state.goTobankLoading){
      loadingMessage = Dictionary.RedirectingToBank +" ...";
    }else if (this.state.pageState === "unsuccessful"){
      loadingMessage = Dictionary.ReturningFromTheBank +" ...";
    }else if (this.state.pageState === "successful"){
      loadingMessage = Dictionary.IssuingTickets +" ...";
    }else{
      loadingMessage = Dictionary.ChooseYourPaymentMethod+" ...";
    }

    let hideItoursDeposite = false;
    if(domesticHotelInfo || hotelInfo){
      let cookies = decodeURIComponent(document.cookie).split(';');
      for (const item of cookies) {
        if (item.includes('safarMarketHotelId=')) {
          hideItoursDeposite = true;
        }
      }
    }

    return !respond ? (
      <Loading fullPage description={loadingMessage} />
      ):(
      <main className="main-content" ref={this.pageWrapper}>
        {this.props.LAN === "IR" && (
          <div className={`itours-modal ${this.state.showStartHint ? "visible" : ""}`} >
            <div className="modal-overlay" 
              onClick={()=>{this.setState({showStartHint:false})}}
            />
            <div className="modal-inner small-width">
              <div className="text-center modal-main">
                <div className="margin-bottom margin-top">
                  <span className="itours-icon icon-xlarge error-3" />
                </div>
                  مشتری گرامی لطفا اسامی مسافران و اطلاعات  {flightInfo ? "پرواز" : hotelInfo ? "هتل" : "پرواز و هتل"} خود را بررسی فرمایید و در صورت مغایرت اقدام به جستجو و رزرو مجدد نمایید
                <br/>
                <button
                  type="button"
                  onClick={()=>{this.setState({showStartHint:false})}}
                  className="button blue-btn margin-bottom-large margin-top-large"
                >
                  {Dictionary.ok}
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="page-padding reserve-page">
          <div className="page-container">
            {(hotelInfo || flightInfo || packageInfo || domesticHotelReserveInfo) && (
              <>
                {directTicketResult ? null : <div>
                  <Steps current={2}>
                    <Step title={Dictionary.chooseFlightsOrHotels} />
                    <Step title={Dictionary.enterThePassengersInformation} />
                    <Step title={Dictionary.paymentTypeOptions} />
                  </Steps>
                </div>}
                {detailsHint}

<StickySides
  reverseOrderInMobile
  main={(
    <>

    {this.props.directTicketLoading ?
      <Loading className="margin-bottom-large" />
    :
    (flightInfo && directTicketResult) ? <> {
      // (
      //   LayoutConfig.themeClassName === 'naminTravel-theme%%%%%ayanair' && 
      //   flightInfo.airItinerary.originDestinationOptions[0].flightSegments[0].marketingAirline.code === "TK" && 
      //   flightInfo.providerName.toLowerCase === "ethnic"
      // ) ?
      // <div className="red-card card card-padding message-block box-border margin-bottom">
      //   {/* %%%%%%%%%%%%%%%%% */}
      //   {/* <p className="semi-bold font-18">
      //     <span className="itours-icon circle-error margin-end-half" />
      //     {Dictionary.SorryYourTicketWasNotIssued}.
      //   </p>
      //   <div className="semi-bold font-18 margin-bottom">{Dictionary.trackingNumber} : {this.state.reserveId}</div>
      //   <span className="inline-block">
      //     {
      //       (LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme')?
      //       Dictionary.ForQuestionsRegardingThisReservationPleaseContactNaminTravelDirectly
      //       :Dictionary.ForQuestionsRegardingThisReservationPleaseContactItoursDirectly
      //     }
      //     :
      //   </span>
      //   {LayoutConfig.contacts?.telephone?.length ? this.phoneItem (LayoutConfig.contacts.telephone[0])
      //   : this.phoneItem(Dictionary.supportNumber)}    */}
      // </div>
      // :
      (directTicketResult!=="Error") && <div className={`${(directTicketResult==="PnrNotMade")?"red-card":"green-card"} card card-padding message-block box-border margin-bottom clearfix`}>
      {(directTicketResult==="PNRIssued")?
      <>
        <p className="semi-bold font-18">
          <span className="itours-icon check-green margin-end-half" />
          {LAN==="IR" && <span className="margin-end-light">{flightInfo.reserver.firstName} عزیز،</span>}
          {Dictionary.YourTicketHasBeenIssuedSuccessfully}.
        </p>
        <div className="clearfix">
          <a
          href={
            process.env.REACT_APP_THEME === "NAMINTRAVELCOM"?
            `https://voucher.namintravel.com/en/flight/Ticket2?reserveid=${this.state.reserveId}&username=${flightInfo.reserver.username}`
            : process.env.REACT_APP_THEME ==='AYANAIRCOM'?
            `https://voucher.ayanair.net/en/flight/ayanairTicket?reserveId=${this.state.reserveId}&username=${flightInfo.reserver.username}`
            : (LAN ==="IR")?
            `https://voucher.itours.ir/en/flight/irTicket3?reserveid=${this.state.reserveId}&username=${flightInfo.reserver.username}`
            :
            `https://voucher.imt.as/en/flight/Ticket?reserveid=${this.state.reserveId}&username=${flightInfo.reserver.username}`
          }
            target="_blank"
            rel="noopener noreferrer"
            download
            className="button color-1-btn pull-end"
          >
            {Dictionary.ticket}
          </a>                              
        </div>
      </>
      :(directTicketResult==="PnrNotMade")?<>
        <p className="semi-bold font-18">
          <span className="itours-icon circle-error margin-end-half" />
          {LAN==="IR" && <span className="margin-end-light">{flightInfo.reserver.firstName} عزیز،</span>}
          {Dictionary.SorryYourTicketWasNotIssued}. {Dictionary.PleaseContactOurSupportTeamBeforeTryingAgainForBooking}.
          {LAN==="IR" && "  از این که با شکیبایی خود ما را یاری میفرمایید بسیار سپاسگزاریم."}
        </p>
        <div className="semi-bold font-18 margin-bottom">{Dictionary.trackingNumber} : {this.state.reserveId}</div>
        <span className="inline-block">
          {LayoutConfig.themeClassName === 'naminTravel-theme'?
            Dictionary.ForQuestionsRegardingThisReservationPleaseContactNaminTravelDirectly
            :LayoutConfig.themeClassName === 'ayanair-theme'?
            Dictionary.ForQuestionsRegardingThisReservationPleaseContactAyanairDirectly
            :Dictionary.ForQuestionsRegardingThisReservationPleaseContactItoursDirectly
          }
          :
        </span>
        {LayoutConfig.contacts?.telephone?.length ? this.phoneItem (LayoutConfig.contacts.telephone[0])
        : this.phoneItem(Dictionary.supportNumber)}   
      </>
      :<>
        <p className="semi-bold font-16">
          <span className="itours-icon check-green margin-end-half" />
          {LAN==="IR" && <span className="margin-end-light">{flightInfo.reserver.firstName} عزیز،</span>}
          {Dictionary.YourTicketHasBeenRegisteredAndOurSupportTeamIsIssuingItYourTicketWillBeSentToYouAsSoonAsItIsIssued}
        </p>  
        <div className="semi-bold font-18 margin-bottom">{Dictionary.trackingNumber} : {this.state.reserveId}</div>
        <span className="inline-block">
          {LayoutConfig.themeClassName === 'naminTravel-theme'?
            Dictionary.ForQuestionsRegardingThisReservationPleaseContactNaminTravelDirectly
            :LayoutConfig.themeClassName === 'ayanair-theme'?
            Dictionary.ForQuestionsRegardingThisReservationPleaseContactAyanairDirectly
            :Dictionary.ForQuestionsRegardingThisReservationPleaseContactItoursDirectly
          }
          :
        </span>
        {LayoutConfig.contacts?.telephone?.length ? this.phoneItem (LayoutConfig.contacts.telephone[0])
        : this.phoneItem(Dictionary.supportNumber)} 
      </>
      }
    </div>}
    </>
    : statusBox
    }

    {this.state.reserveId && !this.props.directTicketLoading && !this.state.paymentError && <div className="bg-white card card-padding message-block box-border margin-bottom">
    <div className="semi-bold font-20">{Dictionary.ReservationNumber} : {this.state.reserveId}</div>
    </div>}
    {this.state.paymentError? <div className="card card-padding message-block error-message box-border margin-bottom">
      <h3 className="normal-title">
        <span className="itours-icon icon-large red-info-icon margin-end-half" />
        {Dictionary.unfortunatelyPaymentIsFailed}.
      </h3>
      <div className="semi-bold margin-bottom-half">{Dictionary.trackingNumber} : {this.state.reserveId}</div>
      <p>
        {LayoutConfig.themeClassName === 'naminTravel-theme'?
          Dictionary.ForQuestionsRegardingThisReservationPleaseContactNaminTravelDirectly
          :LayoutConfig.themeClassName === 'ayanair-theme'?
          Dictionary.ForQuestionsRegardingThisReservationPleaseContactAyanairDirectly
          :Dictionary.ForQuestionsRegardingThisReservationPleaseContactItoursDirectly
        }
        :
      </p>
      <div className="clearfix">
        {LayoutConfig.contacts &&
        LayoutConfig.contacts.telephone &&
        LayoutConfig.contacts.telephone.length > 0
        ? this.phoneItem (LayoutConfig.contacts.telephone[0])
        : this.phoneItem(Dictionary.supportNumber)}
      </div>
    </div> 
    :
    // LAN === "IR" ? (                            
    //     ((flightInfo && flightInfo.isDomestic) || directTicketResult || this.props.directTicketLoading )?
    //     null:
    //     <div className="card card-padding message-block error-message box-border margin-bottom">
    //     <h3 className="normal-title">
    //       <span className="itours-icon icon-large red-info-icon margin-end-half" />
    //       رزرو شما با موفقیت در سیستم ثبت شد
    //     </h3>
    //     <p>
    //       با توجه به شرایط کنونی به وجود امده به دلیل
    //       کووید ۱۹ لطفا قبل از پرداخت مبلغ رزرو خود با
    //       شماره  
    //       <span dir="ltr" className="margin-right-light margin-left-light">(+98) 21 91008091</span>
    //        تماس بگیرید.
    //     </p>
    //   </div>                            

    // ) : (
      <>
        {this.state.showError && (
          <div className="card card-padding bg-white box-border margin-bottom text-center">
            <div className="margin-bottom">
              <img
                src={paymentError}
                alt=""
                className="payment-icon"
              />
              <br />
              <strong className="purple margin-bottom">
                {Dictionary.paymentOperationFailed}.
              </strong>
            </div>
            <div className="margin-bottom">
              <strong>{Dictionary.pleaseTryAgain}</strong>
            </div>
            <p>
              {Dictionary.YouCanCallsupportNumberForFurtherTracking} .
            </p>
          </div>
        )}

        {(
          (domesticHotelReserveInfo && domesticHotelReserveInfo.status === Payment.Pending)
          ||
          (hotelInfo && hotelInfo.status === Payment.Pending)
            ||
          (flightInfo && flightInfo.reserveStatus ===Payment.Pending)
          ||
          (packageInfo && (packageInfo.reserveFlightInfo.reserveStatus === Payment.Pending) && (packageInfo.reserveHotelInfo.status === Payment.Pending) )
          ) && (
          <div className="card card-padding message-block success-message box-border margin-bottom">
            <h3 className="normal-title">
              <span className="itours-icon icon-large check-green margin-end-half" />
              {Dictionary.YourReservationIsReadyToBePaid}
            </h3>
            <p>
              {Dictionary.pleaseMakeAReservationAtTheSpecifiedTimeAfterWhichYourReservationWillBeSuspended}.
            </p>
          </div>
        )}
        {hotelInfo && hotelInfo.status === Payment.Canceled && !this.props.bookingUnconfirm && (<div className="card card-padding message-block error-message box-border margin-bottom">
            <h3 className="normal-title">
              <span className="itours-icon icon-large red-info-icon margin-end-half" />
              {Dictionary.YourReservationHasBeenSuspended}.
            </h3>
            <p>
              {Dictionary.ThisReservationHasExpiredAndYourReservationHasBeenSuspendedPleaseSearchAgain}.
            </p>
            <div className="text-center">
              <Link className="button blue-btn" to={"/hotel"}>{Dictionary.returnToHome}</Link>
            </div>
        </div>)}
      </>
    // )
    }

    {/*reserver information*/}
    {flightInfo && (
      <div
        className={
          "bg-white box-border card-padding information margin-bottom"
        }
      >
        <h5 className="normal-title">
          {Dictionary.ReserverInformartion}
        </h5>
        <div>
          <div>
            <span className="item-label margin-end-light">
              {Dictionary.gender}:
            </span>
            {flightInfo.reserver.gender
              ? Dictionary.male
              : Dictionary.female}
          </div>
          <div>
            <span className="item-label margin-end-light">
              {Dictionary.firstName}:
            </span>
            {flightInfo.reserver.firstName}
          </div>
          <div>
            <span className="item-label margin-end-light">
              {Dictionary.lastName}:
            </span>
            {flightInfo.reserver.lastName}
          </div>
          <div>
            <span className="item-label margin-end-light">
              {Dictionary.email}:
            </span>
            {flightInfo.reserver.email}
          </div>
          <div>
            <span className="item-label margin-end-light">
              {Dictionary.phoneNumber}:
            </span>
            <bdo dir="ltr">
              {flightInfo.reserver.phoneNumber}
            </bdo>
          </div>
        </div>
      </div>
    )}

      {domesticHotelReserveInfo && domesticHotelReserveInfo.reserver && (
        <div className="bg-white box-border card-padding information margin-bottom" >
          <h5 className="normal-title">{Dictionary.ReserverInformartion} </h5>
          <div>
            <div>
              <span className="item-label margin-end-light">{Dictionary.gender}: </span>
              {domesticHotelReserveInfo.reserver.gender ? Dictionary.male : Dictionary.female}
            </div>
            <div>
              <span className="item-label margin-end-light">{Dictionary.firstName}: </span>
              {domesticHotelReserveInfo.reserver.firstName}
            </div>
            <div>
              <span className="item-label margin-end-light">{Dictionary.lastName}: </span>
              {domesticHotelReserveInfo.reserver.lastName}
            </div>
            <div>
              <span className="item-label margin-end-light">{Dictionary.email}: </span>
              {domesticHotelReserveInfo.reserver.email}
            </div>
            <div>
              <span className="item-label margin-end-light">{Dictionary.phoneNumber}: </span>
              <bdo dir="ltr">{domesticHotelReserveInfo.reserver.phoneNumber}</bdo>
            </div>
          </div>
        </div>
      )}

    {hotelInfo && hotelInfo.reserver && (
      <div
        className={
          "bg-white box-border card-padding information margin-bottom"
        }
      >
        <h5 className="normal-title">
          {Dictionary.ReserverInformartion}
        </h5>
        <div>
          <div>
            <span className="item-label margin-end-light">
              {Dictionary.gender}:
            </span>
            {hotelInfo.reserver.gender
              ? Dictionary.male
              : Dictionary.female}
          </div>
          <div>
            <span className="item-label margin-end-light">
              {Dictionary.firstName}:
            </span>
            {hotelInfo.reserver.firstName}
          </div>
          <div>
            <span className="item-label margin-end-light">
              {Dictionary.lastName}:
            </span>
            {hotelInfo.reserver.lastName}
          </div>
          <div>
            <span className="item-label margin-end-light">
              {Dictionary.email}:
            </span>
            {hotelInfo.reserver.email}
          </div>
          <div>
            <span className="item-label margin-end-light">
              {Dictionary.phoneNumber}:
            </span>
            <bdo dir="ltr">
              {hotelInfo.reserver.phoneNumber}
            </bdo>
          </div>
        </div>
      </div>
    )}

    {packageInfo && packageInfo.reserveFlightInfo.reserver && (
     <div
     className={
       "bg-white box-border card-padding information margin-bottom"
     }
   >
     <h5 className="normal-title">
       {Dictionary.ReserverInformartion}
     </h5>
     <div>
       <div>
         <span className="item-label margin-end-light">
           {Dictionary.gender}:
         </span>
         {packageInfo.reserveFlightInfo.reserver.gender
           ? Dictionary.male
           : Dictionary.female}
       </div>
       <div>
         <span className="item-label margin-end-light">
           {Dictionary.firstName}:
         </span>
         {packageInfo.reserveFlightInfo.reserver.firstName}
       </div>
       <div>
         <span className="item-label margin-end-light">
           {Dictionary.lastName}:
         </span>
         {packageInfo.reserveFlightInfo.reserver.lastName}
       </div>
       <div>
         <span className="item-label margin-end-light">
           {Dictionary.email}:
         </span>
         {packageInfo.reserveFlightInfo.reserver.email}
       </div>
       <div>
         <span className="item-label margin-end-light">
           {Dictionary.phoneNumber}:
         </span>
         <bdo dir="ltr">
            {packageInfo.reserveFlightInfo.reserver.phoneNumber}
         </bdo>
       </div>
     </div>
   </div>
    )}
    {/*passenger information*/}
    {flightInfo && flightInfo.passengers && (
      <div className="bg-white box-border card-padding passengers-table information margin-bottom">
        <h5 className="normal-title">
          {Dictionary.PassengersInformation}
        </h5>
        <table className="full-width">
          <thead>
            <tr>
              <th>{Dictionary.firstName} - {Dictionary.lastName}</th>
              <th>{Dictionary.type}</th>
              {(LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme') ? <>
                <th>{Dictionary.wheelchair}</th>
                <th>{Dictionary.specialDiet}</th>
              </>:
              <>
                <th>{Dictionary.birthday}</th>
                {flightInfo.passengers.some(passItem=>passItem.nationalId && passItem.nationalId!=="0") ? 
                  <th>
                    {Dictionary.nationalCode}
                  </th>
                :
                <>
                  {flightInfo.passengers.some(passItem=>passItem.passportNumber) && <th>
                    {Dictionary.passportNumber}
                  </th>}
                  {flightInfo.passengers.some(passItem=>passItem.passportExpireDate) && <th>
                    {Dictionary.passportExpDate}
                  </th>}                                      
                </>  
                } 
              </>}
              <th>
                {Dictionary.fare}
              </th>
            </tr>
          </thead>
          <tbody>
            {flightInfo.passengers.map((item, index) => <tr key={index}>
              <td>
                <label className="mobile-label semi-bold margin-end-light">{Dictionary.firstName} - {Dictionary.lastName}: </label>
                {item.title ? "Mr " : "Mrs"}{" "}
                {item.firstName} {item.lastName}
              </td>
              <td>
              <label className="mobile-label semi-bold margin-end-light">{Dictionary.type}: </label>
                {item.code === "ADT" && Dictionary.adult}
                {item.code === "CHD" && Dictionary.child}
                {item.code === "INF" && Dictionary.infant}                                        
              </td>                                    
              {(LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme') ?
                <>
                  <td>
                    <label className="mobile-label semi-bold margin-end-light">{Dictionary.wheelchair}: </label>
                    {item.hasWheelChair?<span className="itours-icon green-checkmark icon-small" />:"-----"}
                  </td>
                  <td>
                    <label className="mobile-label semi-bold margin-end-light">{Dictionary.specialDiet}: </label>
                    {item.mealType?item.mealType:"-----"}
                  </td>
                </>
              :
                <>
                  <td>
                    <label className="mobile-label semi-bold margin-end-light">{Dictionary.birthday}: </label>
                    {moment(item.birthDate).format(flightInfo.isDomestic && (item.nationality == 105 || item.nationality==="IR")?"jYYYY/jM/jD":"YYYY-MM-DD")}
                  </td>
                  {flightInfo.passengers.some(passItem=>passItem.nationalId && passItem.nationalId!=="0") ? 
                    <td>
                      <label className="mobile-label semi-bold margin-end-light">{Dictionary.nationalCode}: </label>
                      {item.nationalId || "--"}
                    </td>
                  :
                    <>
                      {flightInfo.passengers.some(passItem=>passItem.passportNumber) && <td>
                        <label className="mobile-label semi-bold margin-end-light">{Dictionary.passportNumber}: </label>
                        {item.passportNumber || "--"}
                      </td>}
                      {flightInfo.passengers.some(passItem=>passItem.passportExpireDate) && <td>
                        <label className="mobile-label semi-bold margin-end-light">{Dictionary.passportExpDate}: </label>
                        {moment(item.passportExpireDate).format("YYYY-MM-DD") || "-----"}
                      </td>}
                    </>
                  }
                </>
              }
              <td>
                <label className="mobile-label semi-bold margin-end-light">{Dictionary.fare}: </label>
                <Price currency={this.props.currencyCode}>
                  {item.totalFare}
                </Price>
              </td>
            </tr>
            )}
          </tbody>
        </table>
      </div>
    )}

    {packageInfo?.reserveFlightInfo?.passengers && (
      <div className="bg-white box-border card-padding passengers-table information margin-bottom">
        <h5 className="normal-title">
          {Dictionary.PassengersInformation}
        </h5>
        <table className="full-width">
          <thead>
            <tr>
              <th>{Dictionary.firstName} - {Dictionary.lastName}</th>
              <th>{Dictionary.type}</th>
              <th>{Dictionary.birthday}</th>
              {packageInfo.reserveFlightInfo.passengers.some(passItem=>passItem.passportNumber) && <th>
                {Dictionary.passportNumber}
              </th>}
              {(LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme') && <>
                <th>{Dictionary.wheelchair}</th>
                <th>{Dictionary.specialDiet}</th>
              </>}
            </tr>
          </thead>
          <tbody>
            {packageInfo.reserveFlightInfo.passengers.map((item, index) => <tr key={index}>
              <td>
                <label className="mobile-label semi-bold margin-end-light">{Dictionary.firstName} - {Dictionary.lastName}: </label>
                {item.title ? "Mr " : "Mrs"}{" "}
                {item.firstName} {item.lastName}
              </td>
              <td>
                <label className="mobile-label semi-bold margin-end-light">{Dictionary.type}: </label>                                      
                {item.code === "ADT" && Dictionary.adult}
                {item.code === "CHD" && Dictionary.child}
                {item.code === "INF" && Dictionary.infant}                                      
              </td>
              <td>
                <label className="mobile-label semi-bold margin-end-light">{Dictionary.birthday}: </label>
                {moment(item.birthDate).format("YYYY-MM-DD")}
              </td>
              {packageInfo.reserveFlightInfo.passengers.some(passItem=>passItem.passportNumber) &&<td>
                <label className="mobile-label semi-bold margin-end-light">{Dictionary.passportNumber}: </label>
                {item.passportNumber || "-----"}
              </td>}
              {(LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'ayanair-theme') && <>
              <td>
              <label className="mobile-label semi-bold margin-end-light">{Dictionary.wheelchair}: </label>
              {item.hasWheelChair?<span className="itours-icon green-checkmark icon-small" />:"-----"}
              </td>
              <td>
              <label className="mobile-label semi-bold margin-end-light">{Dictionary.specialDiet}: </label>
                {item.mealType?item.mealType:"-----"}
              </td>
              </>}
            </tr>
            )}
          </tbody>
        </table>
      </div>
    )}

    {!directTicketResult && <div className="choicePayment margin-bottom">
      {(
        (domesticHotelReserveInfo && domesticHotelReserveInfo.status === Payment.Pending) ||
        (hotelInfo && hotelInfo.status === Payment.Pending) ||
        (packageInfo && (packageInfo.reserveFlightInfo.reserveStatus === Payment.Pending) && (packageInfo.reserveHotelInfo.status === Payment.Pending) ) ||
        (flightInfo && flightInfo.reserveStatus === Payment.Pending)) && (
        <div className="bankSelect">
          <h5 className="normal-title">
            {Dictionary.ChooseYourPaymentMethod}                                  
          </h5>
            {LAN === "IR" && <div className="card card-padding message-block error-message box-border margin-bottom">
              <h3 className="normal-title">
                <span className="itours-icon icon-large red-info-icon margin-end-half" />
                لطفا توجه داشته باشید که درگاه پرداخت فقط با آی پی داخل ایران قابل استفاده می باشد.
              </h3>
            </div>}
          <div>
            {!banks ? (
              <Loading description={Dictionary.loadingPaymentInformation+" ..."} />
            ) : (
              <PaymentList
                banks={banks}
                selectedBankthis={this.bankSelect}
                totalPrice = {hotelInfo ? hotelInfo.salePrice
                  : domesticHotelReserveInfo? domesticHotelReserveInfo.totalPrice
                  : flightInfo ? flightInfo.flightFare.totalFare
                  : packageInfo ? (packageInfo.reserveHotelInfo.salePrice + packageInfo.reserveFlightInfo.flightFare.totalFare)
                  :null
                } 
                maxAmount={isItoursIr ? 1000000000 : undefined}                                     
              />
            )}
          </div>

          { isItoursIr && !hideItoursDeposite && !directTicketResult && <PaymentByDeposit
            maxAmount={1000000000}
            reservId = {this.state.reserveId}
            username = {this.state.username || user_name}
            currency={
              flightInfo ? flightInfo.currency
              :hotelInfo ? hotelInfo.currency?.code 
              : domesticHotelReserveInfo? domesticHotelReserveInfo.currencyType
              : packageInfo ? packageInfo.currency
              :this.props.currencyCode
            }
            totalPrice = {hotelInfo ? hotelInfo.salePrice
            : domesticHotelReserveInfo ? domesticHotelReserveInfo.totalPrice
            : flightInfo ? flightInfo.flightFare.totalFare
            : packageInfo ? (packageInfo.reserveHotelInfo.salePrice + packageInfo.reserveFlightInfo.flightFare.totalFare)
            :null
            }
            initialDepositOpen = {this.state.initialDepositOpen}
          />}
        </div>
      )}
    </div>}

    {process.env.REACT_APP_THEME !== "ITOURS_TR" && <div className="card-padding bg-white box-border clearfix">
      <span className="inline-block">
        {LayoutConfig.themeClassName === 'naminTravel-theme'?
          Dictionary.ForQuestionsRegardingThisReservationPleaseContactNaminTravelDirectly
          :LayoutConfig.themeClassName === 'ayanair-theme'?
          Dictionary.ForQuestionsRegardingThisReservationPleaseContactAyanairDirectly
          :Dictionary.ForQuestionsRegardingThisReservationPleaseContactItoursDirectly
        }
        :
      </span>
      {LayoutConfig.contacts &&
      LayoutConfig.contacts.telephone &&
      LayoutConfig.contacts.telephone.length > 0
      ? this.phoneItem (LayoutConfig.contacts.telephone[0])
      : this.phoneItem(Dictionary.supportNumber)}
    </div>}
  </>
  )}
  aside={(
    <>
    {flightInfo && (
      <FlighSummary
        detailsIsOpen={this.state.detailsIsOpen}
        closeDetailsModal={()=>{this.setState({detailsIsOpen:false})}}
        showFlightAllDetails
        itinTotalFare ={flightInfo.flightFare}
        providerName={flightInfo.providerName}
        model={flightInfo.airItinerary.originDestinationOptions}
        fareInfo={flightInfo.passengersFare}
        totalPrice={flightInfo.flightFare.totalFare}
        currencyCode={flightInfo.currency}
        showButton={false}
        feild={"fare"}
      />
    )}
    {hotelInfo && hotelInfo.accommodation !== undefined && (
      <SummaryHotel
        model={hotelInfo}
        currencyCode={this.props.currencyCode}
        showButton={false}
        totalPrice={totalPrice}
        night={night}
        cancellationPolicy={
          hotelInfo.cancellationPolicy
        }
      />
    )}
    {packageInfo && (
      <PackageSummary
      detailsIsOpen={this.state.detailsIsOpen}
      closeDetailsModal={()=>{this.setState({detailsIsOpen:false})}}
      currencyCode={this.props.currencyCode}
      flightModel={packageInfo.reserveFlightInfo.airItinerary.originDestinationOptions}
      hotelModel={packageInfo.reserveHotelInfo}
      night={packageInfo.reserveHotelInfo.date.duration}
      cancellationPolicy={packageInfo.reserveHotelInfo.cancellationPolicy}

      flightPrice={packageInfo.reserveFlightInfo.flightFare.totalFare}                              
      />
    )}
    {!!domesticHotelInfo && domesticHotelReserveInfo && (
      <DomesticHotelSummery 
      hotelInformation={hotelInformation}
      reserveInformation={reserveInformation}
      currencyCode={this.props.currencyCode}
      //safarmarketId={safarmarketId}
      //cancellationPolicy={hotelInformation.cancellationPolicy}                            
      />
    )}                    
    {this.state.expirationTime && !this.state.paymentError && <RemainingTime startTime={this.state.startTime} expirationTime={this.state.expirationTime} />}
           
    {
    (
        (hotelInfo && hotelInfo.status === Payment.Pending) 
      ||
        (packageInfo && packageInfo.reserveFlightInfo.reserveStatus === Payment.Pending && packageInfo.reserveHotelInfo.status === Payment.Pending ) 
      ||
        (flightInfo && flightInfo.reserveStatus === Payment.Pending)
    ) && (<div className="box-border top-color-border orange margin-top bg-white">
      <div className="colored">
        {Dictionary.pricesToRiseSoon}!
      </div>
      <div className="font-12">
        {Dictionary.bookNowToGetTheBestRateForYourCheckinDate}.
      </div>
    </div>)}


  </>    
  )}
/>

              </>
            )}
          </div>
        </div>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  reserveId: state.HotelSearch.reserveId,
  respond: state.VisualData.Responded,
  banks: state.PaymentData.banks,
  tokenId: state.PaymentData.tokenId,
  ///
  flightInfo: state.FlightSearch.flightSelected,
  hotelInfo: state.HotelSearch.selectedHotel,
  packageInfo: state.PackageSearch.reservedPackageData,
  domesticHotelReserveInfo: state.DomesticHotel.resrevedDomesticHotel,
  domesticHotelInfo: state.DomesticHotel.domesticHotelByIdInfo,
  currencyCode: state.UserData.Currency,
  searchURL: state.HotelSearch.searchURL,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
  directTicketResult:state.FlightSearch.directTicketByIdResult,
  err:state.VisualData.error,
  directTicketLoading:state.FlightSearch.getDirectTicketByIdLoading,
  ipLocation :state.VisualData?.ipLocation,
  bookingUnconfirm: state.HotelSearch?.hotelBookingUnconfirm
});
const mapActionToProps = (dispatch) => ({
  getBankList: (params) => {
    dispatch(getBankList(params));
  },
  editMessage: (params) => {
    dispatch(editMessage(params));
  },
  setBankID: (params) => {
    dispatch(setBankID(params));
  },
  getReserveByID: (params) => dispatch(getReserveByID(params)),
  bookhotel: (params) => dispatch(bookhotel(params)),
  GetReserveById: (params) => dispatch(GetReserveById(params)),
  //getDirectTicketByID: (params) => dispatch(getDirectTicketByID(params)),
  setFlightSelected: (param) => dispatch(setFlightSelected(param)),
  selectedHotel: (param) => dispatch(selectedHotel(param)),
  paymentByMethod: (param) => dispatch(paymentByMethod(param)),
  setSafarmarketPixelValue:(data)=>dispatch(setSafarmarketPixel(data)),
  setSafarmarketHotelPixelValue:data => dispatch(setSafarmarketHotelPixel(data)),
  setDirectTicketByIdResult:(data)=>dispatch(setDirectTicketByIdResult(data)),
  showError: (param) => dispatch(showError(param)),
  setIpLocation : data => dispatch (setIpLocation(data))
});
export default connect(mapStateToProps, mapActionToProps)(Reserve);
