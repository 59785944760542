import { useState } from "react";
import { useSelector } from "react-redux"
import { Link } from "react-router-dom/cjs/react-router-dom";

const AcceptRemarksModal = props => {

  const Dictionary = useSelector(state => state.VisualData.Dictionary);
  
  const [termsAccepted, setTermsAccepted] = useState(false);

  const isNamin = process.env.REACT_APP_THEME === "NAMINTRAVELCOM";
  const isItoursIr = process.env.REACT_APP_SERVER_LOCALIP === "itours.ir";

  return (
    <div className="itours-modal login-to-book-modal visible">
      <div className="modal-overlay" />
      <div className="modal-inner medium-width">
        <div className="modal-main no-height-limit large-padding-desktop">

          <h5 className="bold font-18 margin-bottom"> {Dictionary.PleasePayAttention} </h5>

          <p>
            {Dictionary.remarkP1}
            <br />
            {Dictionary.remarkP2}
          </p>

          <p>
            {Dictionary.remarkP3}
          </p>


          <h5 className="bold purple font-18 margin-bottom-light"> {Dictionary.rules}: </h5>

          <div className="justified en-font margin-bottom-large semi-bold" dir="ltr" dangerouslySetInnerHTML={{ __html: props.remarks }} />

          <p>
            {isItoursIr ? (
              "در صورتی که نیاز به پشتیبانی دارید با شماره 02191008091 در ارتباط باشید"
            ) : isNamin ? (
              Dictionary.naminRemarkSupport
            ) : null}
          </p>

          <div className="checkbox-holder has-link">
            <input
              className="custom-checkbox"
              type="checkbox"
              onChange={e => { setTermsAccepted(e.target.checked) }}
              checked={termsAccepted}
            />
            <label className="checkbox-label font-14">
              {process.env.REACT_APP_SERVER_LOCALIP === "itours.ir" ? (
                <>
                  <Link to={'/terms'} target="_blank" className="content-link">
                    قوانین و مقررات آیتورز
                  </Link> و ملاحظات رزرو این هتل را خوانده ام و با آن موافق هستم.
                </>
              ) : (
                <>
                  {Dictionary.iHaveReadAndAgreeToThe}
                  <Link to={'/terms'} className="content-link" target="_blank">
                    {Dictionary.termsAndConditions}
                  </Link>
                </>
              )}
            </label>
          </div>

          <div className="text-end">
            <button
              disabled={!termsAccepted}
              type="button"
              className="button green-btn large-btn"
              onClick={termsAccepted ? props.onAccept : undefined}
            >
              {Dictionary.accept} {props.submitLoading ? <span className="loading-inline-circle margin-start-light" /> : null}
            </button>
          </div>

        </div>
      </div>
    </div>
  )
}

export default AcceptRemarksModal;