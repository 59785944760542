import React from "react";
import itoursLogo from "../image/logo.svg";
import sunhotelLogo from "../image/sunhotel-logo.svg";
import sunhotelWhiteLogo from "../image/sunhotel-logo-white.svg";
import itoursBanner from "../image/bg(7).jpg";
import style3FlightBg from '../image/style3/sky.jpg';
import style3HotelBg from '../image/style3/hotel-bg.jpg';
import style3PackageBg from '../image/style3/package-bg.jpg';
import naminBanner from "../image/namintravel-slider-images/bg.jpg";
import naminHotelBanner from "../image/namintravel-slider-images/hotel-bg.jpg";
import naminPackageBanner from "../image/namintravel-slider-images/package-bg.jpg";
import sunhotelBanner from "../image/sunhotel-banner.jpg";
import padidehComLogo from "../image/padideh-logo.png";
import padidehComBanner from "../image/padide-com-banner.jpg";
import naminTravelLogo from "../image/naminTravelLogo.png";
import norTravelLogo from "../image/norTravelLogo.png";
import ayanAirLogo from "../image/ayanair/ayanAir-logo.svg";
import ayanAirBanner from '../image/ayanair/home-banner.jpg';
import ayanAirHotelBanner from '../image/ayanair/home-hotel-banner.jpg';
import itoursWhiteLogo from '../image/logo-white.svg';
import TravelportLogo from '../image/travelportLogo.png';


let LayoutConfig;
switch (process.env.REACT_APP_THEME) {
  case "SUNHOTEL_IR":
    LayoutConfig = {
      themeClassName: "sunhotel-theme",
      favicon: "/sunhotel-favicon.ico",
      hasDomesticFlight:true,
      companyName: "Sunhotel", // logo alt
      homeBanner: sunhotelBanner, //home page banner image
      homeSlogancolor: "#fff", //banner text color
      sloganText: {
        largeText: "رزرو هتل های خارجی و داخلی",
        smallText: "با بهترین قیمت و بیشترین انتخاب",
      },
      logo: sunhotelLogo, //site logo
      footerLogo: sunhotelWhiteLogo,
      siteTitle: "سان هتل | خرید بلیط هواپیما ، رزرو هتل خارجی ، تور مسافرتی", //html title attribute
      contacts: {
        instagram: "https://www.instagram.com/sunhotel.ir/",
        telephone: ["(+98) 21 88712609"],
        email: "online@itours.ir",
        address:
          "تهران،خالد اسلامبولی،نبش خیابان سوم،ساختمان 222 پلاک 27 طبقه دوم واحد 2",
      },
      copyrightText: "تمامی حقوق سان هتل محفوظ است",
    };
    break;
  case "PADIDEH81COM":
    LayoutConfig = {
      themeClassName: "padide-com-theme",
      favicon: "/padideh-favicon.ico",
      companyName: "Padideh 81", // logo alt
      homeBanner: padidehComBanner, //home page banner image
      //homeSlogancolor: "#fff", //banner text color

      logo: padidehComLogo, //site logo
      //footerLogo: sunhotelWhiteLogo,
      siteTitle: "Padideh 81: Search Hotels & Cheap Flights", //html title attribute
      aboutUsParagraphs: [
        'Padide81 Air Travel and Tourism Services Company started its official activities in 2002 and now, after many years of professional activity, it has been able to provide special and desirable services by using international standards, resources and access quickly. Find its place in this industry and currently has active branches in Iran, Afghanistan and Dubai with "IATA" code "33213106" to provide',
        "Types of travel services, including a variety of domestic and foreign hotels and flights, visa requirements, special travel insurance policies, airport transfers, various urban cruises, car rental, CIP services and the provision of diverse and flexible flight and hotel packages. It has many agencies, from the Middle East to the United States, Europe and Australia.",
        "The arrival of an international and renowned group in the field of tourism in the Middle East, with active and well-known branches around the world, promises a great movement in the tourism and tourism industry. This collection is productive with elite youth along with internationally renowned engineers in different countries; Sweden and Australia have expanded their operations.",
        "During these years, we have made the highest satisfaction of our travelers and colleagues all over the world, especially Afghanistan, Turkey, Tajikistan, Azerbaijan, Armenia, etc., and we strive with the strongest infrastructure, the highest standards and the most complete network with Provide the highest quality and most diverse distribution of tourism services around the world.",
        "Due to the international nature at the Padide81, it is possible to provide direct access and connection to the world's largest reservation and distribution systems for our collection, and all these successes are due to the collection's access and brilliant background and 18 years of activity. At the heart of Europe, the United States and Asia, and competition with world-renowned celebrities is possible.",
        "Due to the efficiency of modern and unique technology, Padideh81 has made it possible to provide the above services with price guarantee, quality guarantee and at any time online and in person.",
        "We are always grateful for the facilities provided by technology, and with the proper use of these facilities and unremitting efforts, we have succeeded in taking a big step in this industry and providing valuable services to all the people of the world.",
      ],
      contacts: {
        instagram: "disabled",
        telephone: ["00979128360619"],
        email: "admin@padide81.com",
        address: "Unit 27, Souk Madinat Jumeriah, Dubai United Arab Emirates ",
      },
      copyrightText: "2021 Padideh 81, All rights reserved",
    };
    break;
  case "PADIDEH81IR":
    LayoutConfig = {
      themeClassName: "padide-com-theme",
      favicon: "/padideh-favicon.ico",
      companyName: "Padideh 81", // logo alt
      homeBanner: padidehComBanner, //home page banner image
      //homeSlogancolor: "#fff", //banner text color

      logo: padidehComLogo, //site logo
      //footerLogo: sunhotelWhiteLogo,
      siteTitle: "پدیده 81 | خرید بلیط هواپیما ، رزرو هتل خارجی ، تور مسافرتی", //html title attribute
      aboutUsParagraphs: [
        'شرکت خدمات مسافرت هوایی و جهانگردی پدیده هشتاد و یک فعالیت رسمی خود را از سال ۲۰۰۲ آغاز نمود و اکنون بعد از سال ها فعالیت حرفه ای با بهره گیری از استانداردها، منابع و دسترسی های بین المللی به سرعت توانست با ارائه خدماتی خاص و مطلوب، جایگاه خود را در این صنعت پیدا کند و در حال حاضر با دارا بودن شعب فعال در کشورهای ایران، افغانستان و دبی با "یاتا" کد " 33213106" مشغول به ارائه ی انواع خدمات سفر، شامل انواع هتل ها و پروازهای داخلی و خارجی، دریافت ویزا، انواع بیمه نامه های اختصاصی ِ سفر، ترانسفر فرودگاهی، کروزهای متفاوت شهری، اجاره خودرو، خدمات CIP و ارائه پکیج های متنوع و انعطاف پذیر پرواز و هتل ... به تعداد زیادی آژانس، از خاورمیانه گرفته تا کشورهای آمریکا و اروپا و استرالیا می باشد.',
        "ورود مجموعه ای بین المللی و صاحب نام در حوزه گردشگری به خاورمیانه، با شعبات فعال و نام دار در سراسر جهان ،نوید بخش حرکتی بزرگ در صنعت توریسم و گردشگری است. این مجموعه با بهره وری از جوانان نخبه در کنار مهندسین نام آشنای بین المللی در کشورهای متفاوت؛ اعم از سوئد و استرالیا، کار خود را توسعه داده است. ",
        "ما در این سال ها رضایت کامل مسافران و همکارانمان در سراسر جهان بالاخص کشورهای افغانستان، ترکیه، تاجیکستان، آذربایجان، ارمنستان و... را والاترین هدف خود قرار داده ایم و می کوشیم با قوی ترین زیرساخت ها، بالاترین استانداردها و کامل ترین شبکه توزیع با کیفیت ترین و متنوع ترین خدمات در زمینه گردشگری را به سراسر دنیا ارائه نماییم.",
        "با توجه به ماهیت بین المللی پدیده هشتاد و یک، امکان دسترسی های مستقیم و ارتباط با بزرگترین سیستم های رزرواسیون و توزیع جهانی برای مجموعه ما فراهم می باشد و تمامی این موفقیت ها به واسطه ی دسترسی ها و پیشینه ی درخشان مجموعه و فعالیت 18 ساله ی آن در قلب اروپا، آمریکا و آسیا ،و رقابت با صاحب نامان جهانی این عرصه میسر گشته است. پدیده هشتاد و یک به دلیل بهره وری از تکنولوژی روز و منحصر به فرد، باعث شده است تا ارائه خدمات فوق با تضمین قیمت ، گارانتی کیفیت و در هر زمانی به صورت آنلاین و حضوری امکان پذیر گردد",
        "ما همواره قدردان امکاناتی هستیم که تکنولوژی در اختیارمان قرار می دهد و با بهره گیری صحیح از این امکانات و تلاش بی وقفه، موفق شده ایم گامی بزرگ در این صنعت برداشته و خدماتی ارزشمند به تمامی مردم جهان ارائه دهیم.",
      ],
      contacts: {
        instagram: "https://www.instagram.com/padide81.ir/",
        telephone: [],
        email: "info@padide81.ir",
        address: "تهران ، خیابان بهشتی ، خ وزرا پلاک ۲۷ ، واحد ۲۲ ",
      },
      copyrightText: "تمامی حقوق پدیده 81 محفوظ است",
    };
    break;
  case "ITOURS_IR":
    LayoutConfig = {
      //themeClassName: "style-2",
      themeClassName: "style-3",
      //themeClassName: "default-theme",
      hasDomesticFlight:true,
      hasDomesticHotel:true,
      favicon: "/favicon-ir.ico",
      safarmarket:true,
      yabex:true,
      homeBanner: itoursBanner,
      homeFlightBanner : style3FlightBg,
      homeHotelBanner:style3HotelBg ,
      homePackageBanner: style3PackageBg,
       sloganText: {
         largeText: "سفر بعدی خود را رزرو کنید",
         smallText: "تجربه انتخابی هوشمند، از بین بیش از 2 میلیون هتل و 450 ایرلاین در سراسر جهان",
       },
      logo: itoursWhiteLogo,
      whiteLogo : itoursWhiteLogo,
      siteTitle: "آیتورز | خرید بلیط هواپیما ، رزرو هتل خارجی ، تور مسافرتی",
      contacts: {
        instagram: "https://instagram.com/itours.ir?igshid=ZDc4ODBmNjlmNQ==",
        telegram:"https://t.me/iToursIran",
        telephone: ["(+98) 21 91008091","(+98) 901 899 5896"],
        email: "online@itours.ir",
        address: "تهران ، خیابان بهشتی ، خ وزرا پلاک ۲۷ ، واحد ۲۲",
      },
      copyrightText: "کلیه حقوق این وبسایت محفوظ و متعلق به آژانس پدیده هشتاد و یک (آیتورز) می باشد.",
      //copyrightText:"کلیه حقوق این سرویس محفوظ و متعلق به شرکت ........ میباشد"
    };
    break;
  case "ITOURS_NO":
    LayoutConfig = {
      themeClassName: "default-theme",
      companyName: "Itours",
      homeBanner: itoursBanner,
      homeSlogancolor: "#684286",
      logo: itoursLogo,
      languages: ["US","DK","NO","SE", "AR", "TR"],
      contacts: {
        email: "online@itours.no"
      },
      copyrightText: <p className="margin-top">
        Internasjonal Tours AS*Torggata 31, 0183 Oslo. Org Nr : 990183694 mva.nr.VAT : 990183694*+47 22117744
        <br/>2022 Itours.No. All Rights Reserved
      </p>
    };
    break; 
  case "ITOURS_FI":
    LayoutConfig = {
      themeClassName: "default-theme",
      companyName: "Itours",
      homeBanner: itoursBanner,
      homeSlogancolor: "#684286",
      logo: itoursLogo,
      languages: ["US","DK","NO","SE", "AR", "TR"],
      contacts: {
        email: "online@itours.no"
      },
      copyrightText: <p className="margin-top">
        Internasjonal Tours AS*Torggata 31, 0183 Oslo. Org Nr : 990183694 mva.nr.VAT : 990183694*+47 22117744
        <br/>2022 Itours.No. All Rights Reserved
      </p>
    };
    break; 

  case "ITOURS_SE":
  case "ITOURS_DK":
    LayoutConfig = {
      themeClassName: "default-theme",
      companyName: "Itours",
      homeBanner: itoursBanner,
      homeSlogancolor: "#684286",
      logo: itoursLogo,
      languages: ["US","DK","NO","SE", "AR", "TR"],
      contacts: {
        email: "online@itours.no"
      },
      copyrightText: <p className="margin-top">
        Internasjonal Tours AS*Torggata 31, 0183 Oslo. Org Nr : 990183694 mva.nr.VAT : 990183694*+47 22117744
        <br/>2022 Itours.No. All Rights Reserved
      </p>
    };
    break;    
  case "NAMINTRAVELCOM":
    LayoutConfig = {
      themeClassName: "naminTravel-theme",
      homeBanner: naminBanner,
      homeHotelBanner: naminHotelBanner,
      homePackageBanner: naminPackageBanner,
      //homeSlogancolor: "#684286",
      //favicon: favicon,
      companyName: "Namin travel", // logo alt
      //homeSlogancolor: "#fff", //banner text color
      // sloganText: {
      //   largeText: "رزرو هتل های خارجی و داخلی",
      //   smallText: "با بهترین قیمت و بیشترین انتخاب",
      // },
      logo: naminTravelLogo, //site logo
      languages: ["US","DK","NO","SE"],
      //footerLogo: sunhotelWhiteLogo,
      siteTitle: "Namin Travel: Search Hotels & Cheap Flights", //html title attribute
      contacts: {
        instagram: "https://instagram.com/namintravel",
        telephone: [],
        email: "post@namintravel.com",
        address: "Torggata 31, Oslo, 0183, Norway",
        facebook:"https://www.facebook.com/profile.php?id=100071381367275"
      }
    };
    break;
  case "AYANAIRCOM":
    LayoutConfig = {
      noPakage:true,
      themeClassName: "ayanair-theme",
      homeBanner: ayanAirBanner,
      homeHotelBanner:ayanAirHotelBanner,
      //homeSlogancolor: "#684286",
      favicon: "/favicon-ayanair.ico",
      companyName: "ayanair", // logo alt
      //homeSlogancolor: "#fff", //banner text color
      // sloganText: {
      //   largeText: "رزرو هتل های خارجی و داخلی",
      //   smallText: "با بهترین قیمت و بیشترین انتخاب",
      // },
      languages: ["US","AR","KU"],
      logo: ayanAirLogo, //site logo
      //footerLogo: sunhotelWhiteLogo,
      siteTitle: "Ayanair: Search Hotels & Cheap Flights", //html title attribute
      contacts: {
        //instagram: "https://instagram.com/namintravel",
        telephone: ["00964 750 4018333"],
        email: "support@ayanair.net",
        address: "Iraq - Kurdistan - Erbil - 40m - English Village Road",
        //facebook:"https://www.facebook.com/profile.php?id=100071381367275"
      }
    };
    break;
  case "ITOURS_AR":
    LayoutConfig = {
      themeClassName: "default-theme",
      hasDomesticFlight:false,
      homeBanner: itoursBanner,
      homeSlogancolor: "#684286",
      logo: itoursLogo,
      languages: ["US","DK","NO","SE", "AR", "TR"],
      // siteTitle: "آیتورز | خرید بلیط هواپیما ، رزرو هتل خارجی ، تور مسافرتی",
      contacts: {
        instagram: "#",
        telephone: [],
        email: "",
        address: "",
      },
    };
    break;  
    
  case "ITOURS_TR":
    LayoutConfig = {
      favicon: "/favicon-travelport.ico",
      themeClassName: "travelport-theme",
      hasDomesticFlight:false,
      homeBanner: itoursBanner,
      homeSlogancolor: "#684286",
      logo: TravelportLogo,
      languages: ["US","TR"],
      siteTitle: "Travelport: Search Hotels & Cheap Flights",
      aboutUsParagraphs :[
        `Bugün dünya köklü, radikal bir değişimin ve büyük ilerlemenin dünyasıdır. Her sabah güneş doğarken teknolojide yeni bir sıçrama bekleyebiliriz; insanoğlu için çok da uzak olmayan geçmişe inanması imkansız olabilecek fırsatlar sağlayan olağanüstü bir gelişme. Dünya genelinde son on yılda turizm sektöründe meydana gelen büyük değişiklikler, sektörün bu değişiklikler karşısında çok dirençli olduğunu ve teknolojik gelişmelerin onu ne ölçüde dönüştürebileceğini kanıtlıyor. Kuşkusuz, farklı davranmak ve bu teknolojik ilerlemeden yararlanmak için uluslararası kaynaklara erişmeye çalışmak, alandaki meslektaşların üstesinden gelmenin anahtarıdır. Bu alandaki teknolojik gelişmeleri ve güçlü ve sürdürebilir uluslararası ilişkilerini kullanan TravelPort şirket, dünya çapında çevrimiçi rezervasyon sistemleriyle rekabet edebilecek ve bunlara eşit benzersiz özelliklere sahip bir online bilet ve otel rezervasyon sistemi üretmeyi başarmıştır.`,
        `2002 yılında firma, resmi çalışmasını TravelPort ismiyle faaliyetin çeşitli bilet rezervasyon hizmetleri, oteller, paketler ve seyahat ve BT ve bilgi teknolojisi kompleksi (turlarımız) ile ilgili diğer hizmetleri sunmak amacıyla çalışmaya başlamıştır. En son ve benzersiz teknolojinin faydalandığından dolayı, TravelPort, yukarıdaki hizmetleri fiyat garantisi, kalite garantisi ve her zaman çevrimiçi olarak sunmayı mümkün kılmıştır. Bizim diğer arkadaşlarımızla fark, geçem 5 yılı içerisinde sürekli ve durmaksızın sistemlerimizi iyileştirme, üretim ve geliştirmesidir. Bugün, çabalarımız, bu endüstrideki eski çağlardan ve küresel ve uluslararası arenadaki varlığımızla destekleniyoruz; uzun yıllardır faaliyet gösteren, parlak bir geleceğe yönelik olarak, bu sistemi çeşitli güncel, kolay ve kaliteli seyahat hizmetlerinden yararlanmak isteyen herkese sunmaktan gurur duymaktayız. `,
        `Teknolojinin bize sağladığı kolaylıklar için her zaman minnettarız. Bu sektörde büyük bir adım atmayı ve bu imkânları doğru kullanarak ve firmanın teknik ekibinin aralıksız çabalarıyla yurttaşlarımıza değerli hizmetler sağlamak bizim için onur sayılır. `
      ],
      contacts: {
        instagram: "disabled",
        telephone: [],
        email: "",
        address: "",
      },
      copyrightText:"Travelport, All rights reserved"
    };
    break;   

  case "NORTRAVELSE":
    LayoutConfig = {
      themeClassName: "norTravel-theme",      
      homeBanner: itoursBanner,
      //homeSlogancolor: "#684286",
      //favicon: favicon,
      companyName: "Nor travel", // logo alt
      //homeSlogancolor: "#fff", //banner text color
      // sloganText: {
      //   largeText: "رزرو هتل های خارجی و داخلی",
      //   smallText: "با بهترین قیمت و بیشترین انتخاب",
      // },
      logo: norTravelLogo, //site logo
      languages: ["US","DK","NO","SE", "AR", "TR"],
      //footerLogo: sunhotelWhiteLogo,
      siteTitle: "Nor Travel: Search Hotels & Cheap Flights", //html title attribute
      aboutUsParagraphs: [
        ""
      ],
      contacts: {
        instagram: "disabled",
        telephone: [],
        email: "---",
        address: "----"
      },
      copyrightText: " 2021 Nor Travel, All rights reserved",
    };
    break;    
  default:

    LayoutConfig = {
      themeClassName: "default-theme",
      companyName: "Itours",
      homeBanner: itoursBanner,
      homeSlogancolor: "#684286",
      logo: itoursLogo,
      contacts: {
        email: "online@itours.no"
      },
    };
}

export default LayoutConfig;
