import React from 'react';
import Autocomplete from '../Common/Autocomplete.js';
import { ServerAddress, Hotel, Header } from '../../Enum/Urls';
import SelectRoom from './DetailSelectRoom';
import { withRouter } from 'react-router-dom';
import queryString from "query-string";
import moment from "moment-jalaali";
import MultiDatePicker from "react-multi-date-picker"
import { connect } from "react-redux";
import { createDatePickerConfig } from '../../helpers/helpers.js';
import { CountryCode } from '../../Enum/Models.js';

/* import flightIcon from '../../image/flight.svg';
import hotelIcon from '../../image/hotel.svg'; */
class RoomSearch extends React.Component {
    state = {
        searchValidator: {
            location: true,
            dates: true
        },
        validation: false,
        searchParam: {
            locationId: "",
            location: "",
            searchType: "",
            nationality: "",
            adults: [2],
            children: [0],
            ages: [0],
            checkin: queryString.parse(this.props.location.search).checkin,
            checkout: queryString.parse(this.props.location.search).checkout,

        },
        night: 0,
        searchMode: false,
        datepickerType: this.props.LAN === "IR" ? "persian" : "gregorian",
        datepickerLocale: this.props.LAN === "IR" ? "fa" : this.props.LAN === "AR" ? "ar" : "en"
    };

    fillFromURL() {
        if (this.props.location.search !== "") {
            let parameters = queryString.parse(this.props.location.search);
            let defaultNationality = "";
            if (parameters?.nationality) {
                defaultNationality = parameters?.nationality;
            } else if (process.env.REACT_APP_SERVER_LOCALSERVER === "itours.ir") {
                defaultNationality = "IR";
            }
            if (this.props.location.pathname === "/hotel/details" || this.props.location.pathname === "/package/details") {
                this.setState({
                    searchParam: {
                        ...parameters,
                        locationId: this.props.hotelNameId?.id,
                        location: this.props.hotelNameId?.name,
                        searchType: 6,
                        nationality: defaultNationality,
                        checkin: parameters.Checkin,
                        checkout: parameters.Checkout,
                        adults: parameters.Adults.split(",").map(item => Number(item)),
                        children: parameters.Children.split(",").map(item => Number(item)),
                        ages: (parameters.Ages) ? parameters.Ages.split(",").map(item => Number(item)) : []
                    }
                }, () => { this.calculateDuration() })
            } else {
                this.setState({
                    searchParam: {
                        ...parameters,
                        checkin: parameters.checkin,
                        checkout: parameters.checkout,
                        nationality: defaultNationality,
                        adults: parameters.adults.split(",").map(item => Number(item)),
                        children: parameters.children.split(",").map(item => Number(item)),
                        ages: (parameters.ages) ? parameters.ages.split(",").map(item => Number(item)) : []
                    }
                }, () => { this.calculateDuration() })
            }

        }
    }
    componentDidMount() {
        this.fillFromURL();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.fillFromURL();
        }
    }
    disabledDate = (index, current) => {
        // Can not select days before today and today
        if (index === "checkout") {
            return current && current <= moment(this.state.searchParam.checkin).add(1, 'day')

        } else {
            return current && current <= moment().endOf('day').subtract(1, 'day');

        }
    };
    resultRef = () => {
        console.log(this.myRef);
    };
    defaultDay = new Date();

    toggleSearchMode = () => {
        this.setState((prevState) => ({
            searchMode: !(prevState.searchMode)
        }));
    }
    handleClick = () => {
        this.setState({
            validation: true,
        });
        let locationValidator = true;
        let dateValidator = true;
        if (this.state.searchParam.location != null && this.state.searchParam.locationId != null) {
            locationValidator = true;
            this.setState(prevState => ({ searchValidator: { ...prevState.searchValidator, location: true } }))
        } else {
            locationValidator = false;
            this.setState(prevState => ({ searchValidator: { ...prevState.searchValidator, location: false } }))
        }
        if (this.state.searchParam.checkin != null && this.state.searchParam.checkout != null) {
            dateValidator = true;
            this.setState(prevState => ({ searchValidator: { ...prevState.searchValidator, dates: true } }))
        } else {
            dateValidator = false;
            this.setState(prevState => ({ searchValidator: { ...prevState.searchValidator, dates: false } }))
        }
        if (locationValidator && dateValidator) {

            let nationalitySegment = "";
            if (process.env.REACT_APP_SERVER_LOCALSERVER === "itours.ir"){
                if (this.state.searchParam?.nationality){
                    nationalitySegment = `&nationality=${this.state.searchParam.nationality}`;
                }else{
                    nationalitySegment = "&nationality=IR";
                }
            }

            if (this.state.searchParam.searchType === 6) {
                this.props.history.push(`/hotel/details?Id=${this.state.searchParam.locationId}&Adults=${this.state.searchParam.adults}&Children=${this.state.searchParam.children}&Ages=${this.state.searchParam.ages}&Checkin=${this.state.searchParam.checkin}&Checkout=${this.state.searchParam.checkout}${nationalitySegment}`);
            } else {
                this.props.history.push(`/hotel/result?locationId=${this.state.searchParam.locationId}&location=${this.state.searchParam.location}&checkin=${this.state.searchParam.checkin}&checkout=${this.state.searchParam.checkout}&adults=${this.state.searchParam.adults}&children=${this.state.searchParam.children}&ages=${this.state.searchParam.ages}${nationalitySegment}`);
            }
        }

    };
    handlePassenger = (e) => {
        let passenger = JSON.stringify(this.state.searchParam);
        passenger = JSON.parse(passenger);
        passenger.adults = e.adult;
        passenger.children = e.children;
        passenger.ages = e.age;
        this.setState({
            searchParam: { ...this.state.searchParam, ...passenger }
        })
    };
    calculateDuration = () => {
        let night = moment(this.state.searchParam.checkout).diff(moment(this.state.searchParam.checkin), 'days');
        this.setState({
            night: night
        })
    };
    toggleDatepicker = () => {
        if (this.props.LAN === "IR") {
            if (this.state.datepickerType === "gregorian") {
                this.setState({
                    datepickerType: "persian",
                    datepickerLocale: "fa"
                })
            } else {
                this.setState({
                    datepickerType: "gregorian",
                    datepickerLocale: "en"
                })
            }
        } else {
            debugger;
        }
    }

    getCallback = (id, text, type) => {
        this.setState({
            searchParam: {
                ...this.state.searchParam,
                locationId: id,
                location: text,
                searchType: type
            },
        });
    };

    render() {
        const { Dictionary, LAN } = this.props;

        let parameters = queryString.parse(this.props.location.search);
        let defaultNationality = "";
        if (parameters?.nationality) {
            defaultNationality = parameters?.nationality;
        } else if (process.env.REACT_APP_SERVER_LOCALSERVER === "itours.ir") {
            defaultNationality = "IR";
        }

        const isItoursIr = process.env.REACT_APP_SERVER_LOCALSERVER === "itours.ir";

        const datepickerConfig = createDatePickerConfig(LAN, this.state.datepickerLocale,)
        return (
            <>
                <div className={"search-box-holder inline clearfix"}>
                    <div className="bg-dark-blue hotel-detail-search-box-grid">
                        <div className='inner-grid'>
                            <div className="form-group hotel-destination-holder">
                                <label className="form-label no-margin-top">{Dictionary.destination}</label>
                                <Autocomplete
                                    defaultValue={this.state.searchParam.locationId}
                                    defaultText={this.state.searchParam.location}
                                    validate={this.state.searchValidator.location}
                                    getValue={this.getCallback.bind(this)}
                                    placeholder={Dictionary.enterYourDestination}
                                    url={ServerAddress.Type + ServerAddress.Hotel + Hotel.GetLocation}
                                    min={3}
                                    name="sample"
                                    param="value"
                                    header={{
                                        ...Header,
                                        "Accept-Language": LAN === "IR" ? "fa-IR" : "en-US"
                                    }}
                                />
                                {this.state.validation && !this.state.searchParam.location && (
                                    <b className="validator-label">{Dictionary.pleaseEnterDestination}</b>
                                )}
                            </div>

                            <div className="form-group">
                                <label className="form-label">{Dictionary.checkIn}</label>
                                <div className="relative">

                                    <MultiDatePicker
                                        fixMainPosition={process.env.REACT_APP_THEME === 'ITOURS_IR'}
                                        calendarPosition={datepickerConfig.calendarPosition}
                                        weekDays={datepickerConfig.weekDays}
                                        digits={datepickerConfig.digits}
                                        className={datepickerConfig.className}
                                        inputClass="multi-datepicker-input"
                                        minDate={this.state.datepickerType === "persian" ? moment().format("jYYYY/jMM/jDD") : moment().format("YYYY/MM/DD")}
                                        format={"YYYY-MM-DD"}
                                        calendar={this.state.datepickerType}
                                        locale={this.state.datepickerLocale}
                                        value={this.state.datepickerType === "persian" ? moment(this.state.searchParam.checkin).format("jYYYY/jMM/jDD") : this.state.searchParam.checkin}
                                        onChange={(d) => {
                                            const e = d.toDate();
                                            this.setState(
                                                {
                                                    searchParam:
                                                    {
                                                        ...this.state.searchParam,
                                                        checkin: moment(e).format("YYYY-MM-DD"),
                                                        checkout: moment(e).add(1, 'day').format("YYYY-MM-DD")
                                                    }
                                                },
                                                () => { this.calculateDuration() }
                                            )
                                        }
                                        }
                                    >
                                        {LAN === "IR" && <button type="button" className="button blue-btn small-btn margin-bottom-half margin-top-half fa-font" onClick={this.toggleDatepicker}>{this.state.datepickerLocale === "fa" ? "تقویم میلادی" : "تقویم شمسی"}</button>}
                                    </MultiDatePicker>
                                    {this.state.validation && !(this.state.searchParam.checkin) && <b className="validator-label">{Dictionary.pleaseSelectCheckinDate}</b>}
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label">{Dictionary.checkOut}</label>
                                <div className="relative">
                                    <MultiDatePicker
                                        fixMainPosition={process.env.REACT_APP_THEME === 'ITOURS_IR'}
                                        calendarPosition={datepickerConfig.calendarPosition}
                                        weekDays={datepickerConfig.weekDays}
                                        digits={datepickerConfig.digits}
                                        className={datepickerConfig.className}
                                        inputClass="multi-datepicker-input"
                                        minDate={
                                            this.state.datepickerType === "persian" ?
                                                moment(this.state.searchParam.checkin).add(1, "day").format("jYYYY/jMM/jDD")
                                                :
                                                moment(this.state.searchParam.checkin).add(1, "day").format("YYYY/MM/DD")
                                        }
                                        format={"YYYY-MM-DD"}
                                        calendar={this.state.datepickerType}
                                        locale={this.state.datepickerLocale}
                                        value={this.state.datepickerType === "persian" ?
                                            moment(this.state.searchParam.checkout, "YYYY/MM/DD").format("jYYYY/jMM/jDD") :
                                            this.state.searchParam.checkout
                                        }
                                        onChange={(d) => {
                                            const e = d.toDate();
                                            this.setState(
                                                {
                                                    searchParam: {
                                                        ...this.state.searchParam,
                                                        checkout: moment(e).format(
                                                            "YYYY-MM-DD"
                                                        )
                                                    },
                                                },
                                                () => {
                                                    this.calculateDuration();
                                                }
                                            );
                                        }}
                                    >
                                        {LAN === "IR" && <button type="button" className="button blue-btn small-btn margin-bottom-half margin-top-half fa-font" onClick={this.toggleDatepicker}>{this.state.datepickerLocale === "fa" ? "تقویم میلادی" : "تقویم شمسی"}</button>}
                                    </MultiDatePicker>


                                    {this.state.validation && !(this.state.searchParam.checkout) && <b className="validator-label">{Dictionary.pleaseSelectCheckoutDate}</b>}
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label"> {Dictionary.nights}</label>
                                <div className="relative">
                                    <div className="relative"><span className="form-input">{this.state.night}</span></div>
                                </div>
                            </div>

                            {!!isItoursIr && <div className="form-group relative">
                                <label className="form-label"> ملیت </label>
                                <select
                                    title="ملیت"
                                    className="form-select nationality-select"
                                    name="nationality"
                                    defaultValue={defaultNationality}
                                    onChange={e => {
                                        this.setState({
                                            searchParam: {
                                                ...this.state.searchParam,
                                                nationality: e.target.value
                                            }
                                        })
                                    }}
                                >
                                    {CountryCode.map((item) => (
                                        <option value={item.value} key={item.value}>
                                            {item.code}
                                        </option>
                                    ))}
                                </select>
                            </div>}


                            <SelectRoom
                                onSelectItem={(e) => { this.handlePassenger(e) }}
                                defaultAdult={this.state.searchParam.adults}
                                defaultChildren={this.state.searchParam.children}
                                defaultAges={this.state.searchParam.ages}
                            />



                        </div>

                        <button className="searchBtn button red-btn" type="button" onClick={this.handleClick}>
                            {Dictionary.search}
                        </button>

                    </div>

                </div>
            </>
        )
    }
}
const RouterDOM = withRouter(RoomSearch)
const mapStateToProps = (state) => ({
    Dictionary: state.VisualData.Dictionary,
    LAN: state.VisualData.LAN
});
export default connect(mapStateToProps)(RouterDOM);