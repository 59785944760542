import React, {PureComponent } from 'react';
import moment from "moment-jalaali";
import {connect} from 'react-redux';
import ShowWeekDay from '../../components/Common/showWeekDay';
import {showDuration} from '../../helpers/helpers'; 

class Itineries extends PureComponent {
    render(){
        const {Dictionary,LAN}=this.props;
        return(
            <div className="flight-itinery-details">
            {
                this.props.model.map((itineryItem,index1)=>{
                    return(
                        <div className="detail-flight-item"  key={index1}>
                            <div className="detail-flight-content">
                                <div className="route-header clearfix">
                                    <div className="pull-start">
                                        <span className="inline-block margin-end-light">
                                            <span className="inline-block hidden-xsmall">{Dictionary.origin}{Dictionary.comma} </span> <b>{itineryItem.flightSegments[0].departureAirport.cityName}</b>
                                        </span>
                                        <span className="itours-icon right-arrow-dark rotate-rtl margin-end-half" />
                                        <span className="inline-block">
                                            <span  className="inline-block hidden-xsmall"> {Dictionary.destination}{Dictionary.comma} </span> <b>{itineryItem.flightSegments[itineryItem.flightSegments.length-1].arrivalAirport.cityName}</b>
                                        </span>
                                    </div>
                                    <div className="pull-end font-14">
                                        <span> {Dictionary.journeyDuration}:  </span>
                                        <strong> 
                                            {showDuration(itineryItem.journeyDuration)} 
                                        </strong>
                                    </div>

                                </div>
                                {
                                    itineryItem.flightSegments.map((item,index,array)=> {
                                        return (
                                            <div key={item.flightNumber}>
                                                <div className="detail-route-item">
                                                    <div className="airline-info font-12">
                                                        <img src={item.marketingAirline.photoUrl} alt={item.marketingAirline.name} className="airline inline-block-middle"/>
                                                        <div>
                                                            <div className="airlinename" title={Dictionary.airline}>{item.marketingAirline.name}</div>
                                                            {item.marketingAirline.code !== item.operatingAirline.code && <div className="airlinename operating-airline">
                                                                Operating by {item.operatingAirline.name}
                                                            </div>}
                                                            <div title={Dictionary.classCabin}> {item.cabinClass.name} ({item.cabinClass.code})</div>
                                                            <div title={Dictionary.flightNumber}><b className="en-font">{item.flightNumber}</b></div>

                                                        </div>                                                                            
                                                    </div>
                                                    <div className="origin-info">
                                                        <div>
                                                            <span className="margin-end-light inline-block hidden-responsive">{item.departureAirport.locationCode}</span>
                                                            <strong className="inline-block takeoff-time">{moment(item.departureDateTime).format("HH:mm")}</strong>
                                                        </div>
                                                        <div className="font-14">
                                                            {
                                                                (LAN === "IR")?
                                                                <>
                                                                    <div className="takeoff-date"><b>{moment(item.departureDateTime).format("jYYYY-jMM-jDD")} </b> <small> ({moment(item.departureDateTime).format("YYYY-MM-DD")})</small></div>                                                                                  
                                                                    <div className="hidden-responsive"><b><ShowWeekDay>{moment(item.departureDateTime).format("dddd")}</ShowWeekDay></b></div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div><b className="takeoff-date">{moment(item.departureDateTime).format("YYYY-MM-DD")}</b></div> 
                                                                    <b className="hidden-responsive">{moment(item.departureDateTime).format("dddd")}</b>
                                                                </>
                                                            }
                                                                                                                                        
                                                        </div>
                                                        <div className="font-12 semi-bold airport-name">
                                                            <span>
                                                                {item.departureAirport.locationName} 

                                                                {(item.departureAirport.terminalID)&& <span className="terminal-id"><span>Terminal: </span>  {item.departureAirport.terminalID}</span>}
                                                            </span>
                                                        </div>
                                                        <div className="font-12">
                                                            <span>{item.departureAirport.cityName}, </span>
                                                            <span>{item.departureAirport.countryName} </span>
                                                        </div>
                                                    </div>
                                                    <div className="destination-info">
                                                        <div>
                                                            <span className="margin-end-light inline-block hidden-responsive">{item.arrivalAirport.locationCode}</span>
                                                            <strong className="inline-block landing-time">{(item.arrivalDateTime === '0001-01-01T00:00:00')?"":moment(item.arrivalDateTime).format("HH:mm")}</strong>
                                                        </div>
                                                        <div className="font-14">
                                                            {(item.arrivalDateTime === '0001-01-01T00:00:00')?"-- : --":                                                                
                                                                (LAN === "IR")?
                                                                <>
                                                                    <div className="landing-date"><b>{moment(item.arrivalDateTime).format("jYYYY-jMM-jDD")} </b> <small> ({moment(item.arrivalDateTime).format("YYYY-MM-DD")})</small></div>                                                                                  
                                                                    <div className="hidden-responsive"><b><ShowWeekDay>{moment(item.arrivalDateTime).format("dddd")}</ShowWeekDay></b></div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div><b className="landing-date">{moment(item.arrivalDateTime).format("YYYY-MM-DD")}</b></div> 
                                                                    <b className="hidden-responsive">{moment(item.arrivalDateTime).format("dddd")}</b>
                                                                </>
                                                            }                                                                                
                                                        </div>                                                                        
                                                        <div className="font-12 semi-bold airport-name">
                                                            {item.arrivalAirport.locationName}
                                                            {(item.arrivalAirport.terminalID)&& <span className="terminal-id"><span>Terminal: </span>  {item.arrivalAirport.terminalID}</span>}
                                                        </div>
                                                        <div className="font-12">
                                                            <span>{item.arrivalAirport.cityName}, </span>
                                                            <span>{item.arrivalAirport.countryName} </span>
                                                        </div>                                                                        
                                                    </div>
                                                    <div className="duration-info text-end">
                                                        {item.flightDuration==="00:00:00" ? "-- : --":                                                        
                                                        <><span className="hidden-medium hidden-large font-14"> {Dictionary.flightDuration} : </span><b className="font-14">{item.flightDuration}</b></>
                                                    }
                                                    </div>
                                                </div>
    
                                                {
                                                    (item.stopTime!=="00:00:00") && (index < array.length-1) &&

                                                    <div className="stop-message">
                                                        {(!(item.arrivalAirport.locationCode === array[index+1].departureAirport.locationCode))?
                                                            <div className="stop-info change-airport clearfix">
                                                                <b>{Dictionary.StopForAirportChange}: </b> 
                                                                <span> {Dictionary.YouWillBeChangingAirports} </span>
                                                                (<b>{item.arrivalAirport.locationCode}</b> {Dictionary.to} <b>{array[index+1].departureAirport.locationCode}</b>).
                                                                {Dictionary.ATransitVisaMayBeRequired}.                   
                                                                <b className="pull-end font-14 stop-top-value">{moment(item.stopTime, "HH:mm:ss").format("HH[h] mm[m]")}</b>
                                                            </div>                                                                                         
                                                        
                                                        :(!(item.arrivalAirport.terminalID === array[index+1].departureAirport.terminalID))?
                                                        <div className="stop-info change-terminal">
                                                            {
                                                            (LAN === "IR")?
                                                            <>
                                                            <b>{Dictionary.stopForTerminalChange}: </b>
                                                            <span> {Dictionary.at} </span>
                                                            <span> {item.arrivalAirport.locationName} </span>
                                                            <span> شما ترمینال را  </span>
                                                            {(item.arrivalAirport.terminalID && array[index+1].departureAirport.terminalID)?
                                                                <>
                                                                ({Dictionary.from} 
                                                                <b> {item.arrivalAirport.terminalID} </b>
                                                                {Dictionary.to} 
                                                                <b> {array[index+1].departureAirport.terminalID}</b>) 
                                                                </>
                                                            :null}
                                                            <span> تغییر خواهید داد.</span>
                                                            </>
                                                            :
                                                            <>
                                                            <span> <b>{Dictionary.stopForTerminalChange}:</b>
                                                                <span> {Dictionary.youWillBeChangingTerminals} </span>
                                                                {(item.arrivalAirport.terminalID && array[index+1].departureAirport.terminalID)?
                                                                <>
                                                                (<b>{item.arrivalAirport.terminalID} </b>
                                                                {Dictionary.to} 
                                                                <b> {array[index+1].departureAirport.terminalID}</b>) 
                                                                </>
                                                                :null}
                                                                <span> {Dictionary.at} </span>
                                                            </span>
                                                            <span> {item.arrivalAirport.locationName} </span>
                                                            </>                                                                                        
                                                            }
                                                            <b className="pull-end font-14 stop-top-value">{moment(item.stopTime, "HH:mm:ss").format("HH[h] mm[m]")}</b>
                                                        </div>             
                                                        :
                                                        <div className="stop-info text-center clearfix">
                                                            <span className="itours-icon white-clock-icon margin-end-half icon-small" />
                                                            <b className="semi-bold"> {process.env.REACT_APP_THEME ==='AYANAIRCOM' ? Dictionary.connectionTimeIn : Dictionary.waitingTimeIn} </b>                                                                            
                                                            <b className="semi-bold"> {item.arrivalAirport.locationName} </b>
                                                            <b className="pull-end font-14 stop-top-value"> {showDuration(item.stopTime)}</b>
                                                        </div>            
                                                        }
                                                    </div>
                                                }

                                            </div>
                                        )
                                    })
                                }


                            </div>
                        </div>
                    )
                })
            }

        </div>
        )
    }
}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export default connect(mapStateToProps)(Itineries);